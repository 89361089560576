import React from "react";
import { useCollapse } from "react-collapsed";
import { useNavigate } from "react-router-dom";

function CollapsedMenuWiki(props) {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  const ChildMenu = (props) => {
    const navigate = useNavigate();
    const goto = (url) => navigate(url);

    return (
      <li className="border-l-2 px-2 py-1">
        <button className="wiki-menu" onClick={() => goto(props.url)}>
          {props.title}
        </button>
      </li>
    );
  };
  return (
    <div className="my-2">
      <button className="wiki-menu" {...getToggleProps()}>
        {/* {isExpanded ? "Collapse" : "Expand"} */}
        {props.title}
      </button>
      <section className="pl-4 text-sm text-gray-500" {...getCollapseProps()}>
        <ul>
          {props.menus.map((menu, index) => {
            return <ChildMenu key={index} title={menu.title} url={menu.url} />;
          })}
        </ul>
      </section>
    </div>
  );
}

export default CollapsedMenuWiki;
