import React from "react";
import { useState } from "react";
import {
  AiFillHeart,
  AiOutlineQuestionCircle,
  AiOutlineSearch,
} from "react-icons/ai";
import { motion } from "framer-motion";
import Logo from "./imgs/yustika-bima.png";
import TemplateNyusss from "./Components/TemplateNyusss";

function SearchYustika() {
  const [isClicked, setIsClicked] = useState(false);
  const questions = [
    {
      id: 1,
      question: "Udah nggak usah nyari lagi",
      icon: <AiFillHeart className="w-6 h-6" />,
    },
    {
      id: 2,
      question: "Mima itu yang nyusss cari",
      icon: <AiFillHeart className="w-6 h-6" />,
    },
    {
      id: 3,
      question: "Semua yang nyusss cari ada di mima",
      icon: <AiFillHeart className="w-6 h-6" />,
    },
    {
      id: 4,
      question: "Mima siap nerima nyusss apa adanya",
      icon: <AiFillHeart className="w-6 h-6" />,
    },
  ];
  const variants = {
    hidden: {
      y: 5,
      opacity: 0,
      transitionEnd: {
        display: "none",
      },
    },
    visible: { display: "block", opacity: 1, y: 0 },
  };

  const variantsBounce = {
    visible: {
      opacity: 1,
      scale: 1,
    },
    hidden: {
      opacity: 0,
      scale: 0,
    },
  };
  const transition = {
    delay: 0.5,
    type: "spring",
    ease: [0, 0.71, 0.2, 1.01],
    damping: 5,
    stiffness: 100,
  };

  return (
    <TemplateNyusss>
      <div className="h-full pb-20 flex flex-col justify-center items-center p-3">
        <div className="w-5/12 my-5">
          <motion.img
            animate={{ rotate: isClicked ? 180 : 0 }}
            src={Logo}
            alt=""
            className=""
          />
        </div>
        <motion.div
          initial="hidden"
          animate={"visible"}
          variants={variantsBounce}
          transition={transition}
          className="shadow-xl rounded-xl p-2 w-full"
        >
          <ul>
            <li
              className="flex items-center gap-2 py-1"
              onClick={() => setIsClicked((value) => !value)}
            >
              <div className="">
                <AiOutlineSearch className="w-6 h-6" />
              </div>
              <div className="text-gray-500">
                <p>Mau nyari apa sih hon?</p>
              </div>
            </li>
            {questions.map((question, i) => {
              return (
                <motion.li
                  initial="hidden"
                  animate={isClicked ? "visible" : "hidden"}
                  variants={variants}
                  transition={{ delay: i * 0.2, type: "spring", damping: 8 }}
                  key={question.id}
                >
                  <div className="flex items-center gap-2 py-1">
                    <div className="text-red-800">{question.icon}</div>
                    {question.question}
                  </div>
                </motion.li>
              );
            })}
          </ul>
        </motion.div>
      </div>
    </TemplateNyusss>
  );
}

export default SearchYustika;
