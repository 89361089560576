import React, { useEffect, useState } from "react";
import { useStateContext } from "../Contexts/ContextProvider";
import DropFile from "../GlobalComponents/DropFile";
import Compressor from "compressorjs";
import SliderImage from "../GlobalComponents/SliderImage";
import { fileToBase64 } from "../GlobalFunction/ConverterUtils";
// import ReactCompareImage from "react-compare-image";
import cover from "../MusicAdmin/Img/cover1.jpg";
import {
  ReactCompareSlider,
  ReactCompareSliderImage,
} from "react-compare-slider";
import ReactCompareImage from "react-compare-image";

function ImageCompressor() {
  const { isDark } = useStateContext();
  const [compressedFile, setCompressedFile] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [files, setFiles] = useState([]);

  useEffect(
    () => () => {
      files.forEach((file) => {
        URL.revokeObjectURL(file.url);
      });
      // objectToArray(files, "url");
    },
    [files]
  );

  const compressFile = (image) => {
    new Compressor(image, {
      quality: 0.8,

      success: (compressedResult) => {
        const blobSource = window.URL.createObjectURL(image);
        setSelectedFile(blobSource);
        const blobCompressed = window.URL.createObjectURL(compressedResult);
        setCompressedFile(blobCompressed);
        // fileToBase64(compressedResult).then((result) => {
        //   console.log(result);
        //   setselectedFile(image);
        //   setCompressedFile(result);
        // });
        // fileToBase64(image).then((result) => {
        //   console.log(result);
        // });
      },
    });
  };

  return (
    <div data-theme={isDark ? "night" : "cupcake"}>
      <div className="transition min-h-screen flex justify-center items-center p-20">
        <div className="rounded-3xl shadow-2xl shadow-slate-300 overflow-hidden w-full">
          <div className="p-10">
            <DropFile files={files} setFiles={setFiles} />
          </div>

          {selectedFile !== null && compressedFile !== null && (
            <div className="flex justify-center">
              <div className="">
                <ReactCompareSlider
                  boundsPadding={0}
                  itemOne={
                    <ReactCompareSliderImage
                      alt="Image one"
                      src={selectedFile}
                    />
                  }
                  itemTwo={
                    <ReactCompareSliderImage
                      alt="Image two"
                      src={compressedFile}
                      // style={{ transform: "scale(1.125)" }}
                    />
                  }
                  position={50}
                  style={{
                    height: "400px",
                    width: "100%",
                  }}
                />
              </div>
            </div>
          )}

          <div className="w-full items-center">
            {files.length > 0 && (
              <SliderImage size={files.length}>
                {files?.map((image, idx) => {
                  return (
                    <div
                      key={idx}
                      className="flex items-center justify-center p-5"
                    >
                      <img
                        src={image.url}
                        alt={image.path}
                        className="object-cover w-44 popular-card aspect-[5/4]"
                        onClick={() => compressFile(image)}
                      />
                    </div>
                  );
                })}
              </SliderImage>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageCompressor;
