import React from 'react'
import { useState } from 'react';
import { motion } from "framer-motion";
// import {Tooltip, Button}from "@material-tailwind/react"
import { Link } from "react-router-dom";
import { BiExit, BiHome, BiMenu, BiMessage } from 'react-icons/bi';
import { MdOutlinePerson } from 'react-icons/md';
import { TbFriends, TbPlaylist } from 'react-icons/tb';
import logo from "../../Img/logo512.png";
import { GrChat, GrHelp } from 'react-icons/gr';
import { useStateContext } from '../../Contexts/ContextProvider';
import { useMediaQuery } from 'react-responsive';
import { useEffect } from 'react';

function NavbarMusic(Content) {
    // const [isOpen, setIsOpen] = useState(true);
    const isBigScreen = useMediaQuery({ query: '(min-width: 1024px)' })
    const {isOpen, setIsOpen} = useStateContext();
    const menus = [
        {nama: 'Dashboard', icon:<BiHome/>, link: '/'},
        {nama: 'Koleksi', icon:<TbPlaylist/>, link: '/'},
        {nama: 'Teman', icon:<TbFriends/>, link: '/'},
        {nama: 'Bantuan', icon:<GrHelp/>, link: '/'},
    ];

    const Menulist = (menu, i) => {
        const [isHover, setIsHover] = useState(false);
        const handleMouseOver = () => {
            setIsHover(true);
        };
    
        const handleMouseOut = () => {
            setIsHover(false);
        };
        return (
            <li key={i} className={`relative ${i===0 ? 'bg-gradient-to-r from-indigo-100 to-orange-100  rounded-l-full pr-0 ml-1' : 'px-1'}`}>
                <Link to={`${menu.link}`} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} className={`flex items-center gap-3 px-4 py-2 my-3 ${i===0 ? 'rounded-l-full' : 'rounded-full' } transition-all duration-700 hover:bg-gradient-to-r from-indigo-300 to-orange-100 hover:shadow-lg overflow-hidden`}>                    
                    <div>
                        {menu.icon}
                    </div>
                    <h3 className={`${isOpen? 'opacity-1' :'opacity-0'}`}>{menu.nama}</h3>
                </Link>   
                {/* <motion.div animate={{scale:1, transition:{duration: 0.5}}} className={`${!isOpen && isHover? 'block' : 'hidden'} tooltip absolute top-3 left-[70px] bg-gradient-to-r from-indigo-300 to bg-orange-100 rounded-full px-4 transition- duration-100 drop-shadow-xl`}>{menu.nama}</motion.div>              */}
                <motion.div initial={{scale: 0}} animate={{scale: !isOpen && isHover? 1 : 0, transition:{duration: 0.5, type: 'spring', damping: '6'}}} className={`tooltip absolute top-3 left-[80px] bg-gradient-to-r from-indigo-300 to bg-orange-100 rounded-full px-4 transition- duration-100 drop-shadow-xl`}>{menu.nama}</motion.div>             
            </li>
        )
    }

    useEffect(() => {
        if(isBigScreen) {
            setIsOpen(true);
        }
        else{
            setIsOpen(false);
        }
    } , []);

    return (
        <nav>            
            <motion.div animate={{width: isOpen ? '200px':'60px', transition: {duration: 0.5, type: 'spring', damping: '10'}}} className={`fixed z-10 bg-navbaradmin min-h-screen flex-row  shadow-sm`}>
                <div className={`relative navbar pt-8 pb-3 flex justify-center items-center border-b-2`}>
                    <motion.div animate={{x: isOpen ? '100px' : '0px', transition: { duration: 0.5, type: 'spring', damping: '10'}}} className={`absolute text-2xl z-10 bg-gradient-to-bl from-indigo-400 to-orange-200 rounded-full p-2 shadow-2xl cursor-pointer drop-shadow-md`} onClick={() => setIsOpen(!isOpen)}><BiMenu/></motion.div>
                    {/* <motion.div animate={{x: isOpen ? '100px' : '0px', transition: { duration: 0.5, type: 'spring', damping: '15'}}} className={`absolute text-2xl  z-10 bg-gradient-to-bl from-indigo-400 to-orange-200 rounded-full p-2 shadow-2xl cursor-pointer drop-shadow-md`} onClick={() => setIsOpen((prevIsOpen) => !prevIsOpen )}><BiMenu/></motion.div> */}
                    {/* <motion.div className={`flex justify-center gap-3 ${isOpen ? 'opacity-1' : 'opacity-0'}`}> */}
                    <motion.div className={`flex justify-center gap-1 items-center`} animate={{x: isOpen ? '0px' : '-100px', transition: { duration: 0.5, type: 'spring', damping: '10'}}}>
                        {/* <img src={logo} alt="f0bima" className='w-8 h-8'/> */}
                        <h1 className='text-hero text-md font-sans font-extrabold'>Music Dashboard</h1>
                    </motion.div>
                </div>
                <ul className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 font-sans font-light text-lg  w-full'>
                    {menus.map((menu, i) => Menulist(menu, i))}
                </ul>
                <div className="absolute logout bottom-0 w-full">
                    <ul className='font-sans font-light text-lg border-t-2'>
                        {Menulist({nama: 'Logout', icon: <BiExit/>}, )}
                    </ul>
                </div>
            </motion.div>
        </nav>        
    )
}

export default NavbarMusic