import React, { Fragment } from 'react'
import { AiFillBackward, AiFillForward } from 'react-icons/ai'
import { BsFillPlayCircleFill, BsMusicNote } from 'react-icons/bs'
import { GrBackTen, GrForwardTen } from 'react-icons/gr'
import Cover from '../Img/240726-little-wing.jpeg'

export default function PlayerMusic() {
    return (
        <Fragment>
            <div className='hidden sm:block relative w-full aspect-[3/4] rounded-2xl shadow-lg bg-cover bg-center overflow-hidden text-white' style={{backgroundImage: `url(${Cover})`}}>
                <div className="absolute flex flex-col items-center justify-evenly w-full h-36 bg-indigo-200 bottom-0 bg-opacity-50" style={{backdropFilter: `blur(20px)`}}>
                    <h3 className="title-audio">Little Wing</h3>
                    <p className='deskripsi text-xs text-center font-light'>Jimy Hendrix</p>
                    <div className="flex justify-evenly items-center py-2 text-3xl w-full">
                        <button><AiFillBackward/></button>
                        <button><GrBackTen className='icon-path'/></button>
                        <button><BsFillPlayCircleFill className='text-indigo-400 text-4xl'/></button>
                        <button><GrForwardTen className='icon-path'/></button>
                        <button><AiFillForward/></button>                    
                    </div>
                </div>
            </div>
            
            <div className="fixed bottom-0 left-0 pl-[60px] block sm:hidden w-full">
                <div className="p-2">
                    <div className="bg-white rounded-full shadow-2xl flex justify-between items-center px-2">
                        
                            <div className="w-14 h-14 flex items-center justify-center">
                                <img src={Cover} alt="" className='circle-act'/>
                            </div>
                        
                        
                        <div className="flex justify-evenly items-center py-2 text-3xl w-full">
                            <button><AiFillBackward/></button>
                            <button><GrBackTen className='icon-path'/></button>
                            <button><BsFillPlayCircleFill className='text-indigo-400 text-4xl'/></button>
                            <button><GrForwardTen className='icon-path'/></button>
                            <button><AiFillForward/></button>                    
                        </div>

                        <div className="">                            
                            <div className="flex justify-center items-center circle-act bg-gradient-to-r from-indigo-100 to-orange-100">
                                <BsMusicNote/>
                            </div>
                        </div>                            
                        
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
