import React from "react";

import { FaLaravel } from "react-icons/fa";
import { GrMysql, GrReactjs } from "react-icons/gr";
import {
  SiAndroidstudio,
  SiArduino,
  SiCodeigniter,
  SiFirebase,
  SiFlutter,
  SiJquery,
  SiPython,
  SiTailwindcss,
} from "react-icons/si";
import ProjectCard from "./ProjectCard";
import musicadmin from "../Img/music-admin.png";
import SSS from "../Img/3S.jpg";
import Devisaudia from "../Img/devisaudia.jpg";
import Elducation from "../Img/Elducation.jpg";
import JurnalFKIP from "../Img/Jurnal FKIP.jpg";
import Siakad from "../Img/Siakad.jpg";

function Project() {
  const projects = [
    {
      judul: "Devisaudia",
      img: Devisaudia,
      tech: [<SiFlutter />],
      desc: "Aplikasi mobile berisi berita terkini mengenai timur tengah dan portal perekrutan kerja",
      link: "https://play.google.com/store/apps/details?id=com.devisaudia.app",
    },
    {
      judul: "Music Dashboard",
      img: musicadmin,
      tech: [<GrReactjs />, <SiTailwindcss />],
      desc: "Konsep design dashboard music player menggunakan React JS dan Tailwind CSS. Project ini menggunakan library framer-motion dan react-slick",
      link: "/musicdashboard",
    },
    {
      judul: "Jurnal FKIP",
      img: JurnalFKIP,
      tech: [<FaLaravel />, <SiPython />, <GrMysql />],
      desc: "Sistem pendukung keputusan klasterisasi publikasi ilmiah FKIP UNS menggunakan K-means. Menggunakan Laravel sebagai framework website. Python sebagai web scraper data jurnal ilmiah dan klasterisasi. Library python : Selenium, scikit-learn, beautifulsoup, scholarly (mod)",
      link: "https://data-kppmf.fkip.uns.ac.id",
    },
    {
      judul: "Smart Security System",
      img: SSS,
      tech: [<SiAndroidstudio />, <SiFirebase />, <SiArduino />],
      desc: "Aplikasi android monitoring berbasis IOT menggunakan Nodemcu dan Firebase. Aplikasi ini dapat memonitoring suhu ruangan, kelembapan udara, system pengunci pintu, dan 8 perangkat elektronik. Teknologi : Android studio java, arduino, firebase",
      link: "https://github.com/f0bima/Smart-Security-System",
    },
    {
      judul: "Siakad",
      img: Siakad,
      tech: [<SiCodeigniter />, <SiJquery />],
      desc: "Project CRUD menggunakan framework CodeIgniter. Studi kasus yang digunakan dalam project ini adalah SIAKAD (Sistem Informasi Akademik)",
      link: "https://github.com/f0bima/siakad-ci",
    },
    {
      judul: "Elducation",
      img: Elducation,
      tech: [<SiAndroidstudio />, <SiFirebase />],
      desc: "Media belajar elektronika dasar berbasis Android. Materi dapat berupa text maupun video youtube yang di-embed ke aplikasi. Terdapat fitur resistor kalkulator. Teknologi: Android Studio Javal, Firebase",
      link: "https://github.com/f0bima/elducation",
    },
  ];

  return (
    <div id="project" className="container mt-3 mb-20">
      <h2 className="font-sans font-medium text-2xl text-center">Projek</h2>
      {/* <div className="project-filter my-8">
                <ul className='flex flex-wrap justify-center'>
                    <li><button className='button mx-1 my-3'>Web</button></li>
                    <li><button className='button mx-1 my-3'>Android</button></li>
                    <li><button className='button mx-1 my-3'>Hardware</button></li>
                    <li><button className='button mx-1 my-3'>Other</button></li>
                </ul>
            </div> */}
      <div className="project-container grid gap-3 px-3 md:px-20 md:grid-cols-2 lg:grid-cols-3 md:gap-3 mt-24">
        {projects.map((project, i) => (
          <ProjectCard
            img={project.img}
            judul={project.judul}
            desc={project.desc}
            tech={project.tech}
            key={i}
            link={project.link}
          />
        ))}
      </div>
    </div>
  );
}

export default Project;
