import React from "react";

export default function ActivityMusic({ act }) {
  return (
    <div className="flex gap-2 py-2 my-2 items-center justify-between">
      <div className="flex gap-2">
        <img src={act.img} alt="" className="circle-act" />
        <div className="">
          <h3 className="font-medium">{act.artist}</h3>
          <p className="text-xs text-gray-500">{act.title}</p>
        </div>
      </div>
      <p className="text-lg">{act.time}</p>
    </div>
  );
}
