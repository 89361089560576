import React from 'react'
import { BsPeopleFill } from 'react-icons/bs'
import ActivityMusic from './ActivityMusic'
import PlayerMusic from './PlayerMusic'
import bonjovi from '../Img/bon-jovi-logo.png'
import pinkfloyd from '../Img/dark-side-of-the-moon.jpg'
import garymoore from '../Img/gary-moore.jpeg'
import direstraits from '../Img/dire straits.jpg'

function RightSidebarMusic() {

    const listActivity = [
        {id: 1, artist: 'Bon Jovi', title: 'Rilis All About Lovin’ You', img: bonjovi, time: "2002"},
        {id: 2, artist: 'Gary Moore', title: 'Rilis Still Got The Blues', img: garymoore, time: "1990"},
        {id: 3, artist: 'Dire Straits', title: 'Rilis Sultans of Swing', img: direstraits, time: "1978"},
        {id: 4, artist: 'Pink Floyd', title: 'Rilis The Great Gig in The Sky', img: pinkfloyd, time: "1973"},
    ]

    return (
        <div className='container-music'>
            <div className="">
                <div className="flex justify-between items-center px-3 md:px-0 text-gray-500">
                    <div className="">
                        <h3 className='text-md font-semibold'>Recent Activity</h3>
                        <p className='text-xs font-light'>22 Activity.</p>
                    </div>
                    <BsPeopleFill className='text-md'/>
                </div>
            </div>
            <div className="mt-5">
                {listActivity.map((act, i) => 
                    <ActivityMusic key={i} act={act}/>                    
                )}
            </div>
            <div className="mt-5">
                <PlayerMusic/>
            </div>
        </div>
    )
}

export default RightSidebarMusic