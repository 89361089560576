import React from "react";
import Nyuss1 from "../imgs/hasbulla2.jpg";
import Logo from "../imgs/yustika-bima.png";
import { AiOutlineHeart, AiOutlineMessage } from "react-icons/ai";
import { motion, useInView } from "framer-motion";
import { useRef } from "react";
import StatusCircleNyusss from "./StatusCircleNyusss";
import { Link } from "react-router-dom";

function HeaderNyusss() {
  const containerRef = useRef(null);

  const message = "Happy 25th birthday Nyuusss ku";

  return (
    <div
      ref={containerRef}
      className="absolute z-40 top-0 p-2 bg-white shadow-xl w-full"
    >
      <div className="flex justify-between w-full py-1 px-2">
        <img className="h-6" src={Logo} alt="Yustika Bima" />
        <div className="flex gap-3">
          <Link to={"/nyusss/notif"} replace>
            <AiOutlineHeart className="w-5 h-5" />
          </Link>
          <Link to={"/nyusss/pesan"} replace>
            <AiOutlineMessage className="w-5 h-5" />
          </Link>
        </div>
      </div>
      <div className="w-full overflow-x-auto scrollbar-hide">
        <div className="flex py-1 w-max">
          {message.split("").map((status, i) => {
            return (
              <StatusCircleNyusss
                key={i}
                rootView={containerRef}
                title={status}
                delay={0.1}
                count={i}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default HeaderNyusss;
