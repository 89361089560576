import { motion, useInView } from "framer-motion";
import React from "react";
import { useRef } from "react";

function StatusCircleNyusss({ title, rootView, delay, count }) {
  const ref = useRef(null);
  const isInview = useInView(ref, { root: rootView, once: false });

  const variants = {
    open: { display: "block", opacity: 1, scale: 1 },
    close: {
      opacity: 0,
      scale: 0,
    },
    hidden: { opacity: 0, scale: 0 },
  };
  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={isInview ? "open" : "close"}
      // animate={"open"}
      transition={{
        delay: count < 4 ? delay : 0.1,
        type: "spring",
        damping: 8,
      }}
      variants={variants}
      className="user-profile px-2"
    >
      <div className="p-1 bg-black rounded-full aspect-square bg-gradient-to-tr from-slate-400 to-lime-300">
        <div
          className={`flex justify-center items-center w-14 h-14 object-cover rounded-full ${
            title !== " "
              ? "bg-gradient-to-br from-pink-500 to-red-300"
              : "bg-red-500"
          } `}
        >
          <p className="font-bold text-xl">{title}</p>
        </div>
      </div>
      {/* <img src={image} alt="" className="w-14 h-14 object-cover rounded-full" /> */}
    </motion.div>
  );
}

export default StatusCircleNyusss;
