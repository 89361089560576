import React from "react";
import { PolarArea } from "react-chartjs-2";

function PolarChart({ chartData }) {
  // const options = {
  //   responsive: true,
  //   maintainAspectRatio: false,
  //   plugins: {
  //     legend: {
  //       position: "top",
  //     },
  //     title: {
  //       display: true,
  //       text: chartData.title,
  //     },
  //   },
  // };

  return (
    // <PolarArea className=' xl:min-h-[360px] lg:min-h-[300px]' data={chartData} options={options}/>
    <PolarArea className="max-h-[380]" data={chartData} />
  );
}

export default PolarChart;
