import React from "react";
import Nyuss from "./imgs/nyuss2.jpg";
import Nyusss from "./imgs/nyuss3.jpg";

// import Posts from "./datas/PostNyuss.json";
import { useRef } from "react";
import HeaderNyusss from "./Components/HeaderNyusss";
import PostCard from "./Components/PostCard";
import Cake from "./animations/happy birthday cake.json";
import Gift from "./animations/happy birthday gift.json";
import Love from "./animations/love hand.json";
import TemplateNyusss from "./Components/TemplateNyusss";
function Yustika() {
  const postContainer = useRef(null);

  const Posts = [
    {
      id: 1,
      caption:
        "Selamat ulang tahun yang ke-25 untuk kekasih tercantik, tersayang, dan terimutku, Yustika Indah Maharani. Aku bersyukur dan berterima kasih kepada Tuhan karena telah memberiku kesempatan untuk mengenal Nyusss dan berbagi perjalanan indah ini bersama Nyusss. Setiap hari bersama Nyusss adalah anugerah, dan aku merasa sangat beruntung memiliki seseorang seistimewa Nyusss di sisiku. Kehadiran Nyusss dalam hidupku membawa kebahagiaan yang luar biasa dan memberi makna yang mendalam. Selamat ulang tahun, cintaku! Semoga hari ini dan setiap hari Nyusss yang akan datang dipenuhi dengan kebahagiaan, cinta, dan kesuksesan.",
      animation: Cake,
      likes: 10,
      comments: 10,
    },
    {
      id: 2,
      caption:
        "Di ulang tahun Nyusss yang ke-25 ini, aku ingin mengungkapkan betapa dalamnya cintaku sama Nyusss. Setiap senyuman Nyusss, aku melihat keajaiban, dan dalam setiap pelukan Nyusss, aku merasakan kenyamanan dan kehangatan. Nyusss bukan hanya orang yang aku cintai, tapi juga batu penyangga, teman curhat, dan pendukung terbesarku. Kehadiran Nyusss memberikan makna yang tak bisa terungkapkan sepenuhnya dalam hidupku. Aku sangat beruntung memiliki Nyusss. Selamat ulang tahun, sayangku! Mari kita terus membangun masa depan yang penuh dengan cinta, tawa, dan kenangan indah. Nyusss sangat berarti di hidupku.",
      animation: Gift,
      likes: 10,
      comments: 10,
    },
    {
      id: 3,
      caption:
        "Nyusss, di usia Nyusss yang ke-25 ini, Nyusss telah mencapai banyak hal yang luar biasa. Semangat Nyusss dalam mengejar impian Nyusss sungguh mengagumkan. Aku bangga melihat bagaimana Nyusss tumbuh dan berkembang menjadi sosok Nyusss yang kuat dan inspiratif. Jangan pernah meragukan potensi Nyusss, karena Nyusss memiliki segala yang diperlukan untuk meraih apa pun yang Nyusss inginkan. Pada hari istimewa ini, aku berjanji untuk selalu mendukung Nyusss dan menjadi pendamping Nyusss dalam perjalanan hidup yang penuh keajaiban. Selamat ulang tahun, kekasihku! Mari kita terus menjalin hubungan yang penuh cinta dan membangun masa depan yang cerah bersama.",
      animation: Love,
      likes: 10,
      comments: 10,
    },
    {
      id: 4,
      caption: "I Love You Yustika ku, Mima sayang banget sama Nyusss",
      image: Nyusss,
      likes: 10,
      comments: 10,
    },
  ];

  return (
    <TemplateNyusss>
      <HeaderNyusss />
      <div
        ref={postContainer}
        className="p-3 h-full overflow-y-auto pt-32 pb-20 scrollbar-hide"
      >
        {Posts.map((post) => {
          return (
            <PostCard
              key={post.id}
              img={post.image}
              animation={post.animation}
              caption={post.caption}
              rootView={postContainer}
            />
          );
        })}
      </div>
    </TemplateNyusss>
  );
}

export default Yustika;
