import React from "react";
import HeaderIndah from "./Components/HeaderIndah";
import ProfileNyusss from "./Components/ProfileNyusss";
import FotonyaNyusss from "./Components/FotonyaNyusss";
import TemplateNyusss from "./Components/TemplateNyusss";
import TypewriterComponent from "typewriter-effect";
import { useState } from "react";
import { motion } from "framer-motion";
import { AiFillCloseCircle } from "react-icons/ai";

function Indah() {
  const [isTypeEnd, setIsTypeEnd] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const variants = {
    visible: { display: "block", opacity: 1, scale: 1 },
    hidden: {
      opacity: 0,
      scale: 0,
      transitionEnd: {
        display: "none",
      },
    },
  };
  const transition = {
    type: "spring",
    damping: 8,
  };

  return (
    <TemplateNyusss>
      <HeaderIndah />
      <motion.div
        initial="hidden"
        animate={selectedImage ? "visible" : "hidden"}
        transition={transition}
        variants={variants}
        className="absolute z-50 w-full h-full bg-pink-300 bg-opacity-50"
      >
        <motion.div
          initial="hidden"
          animate={selectedImage ? "visible" : "hidden"}
          transition={transition}
          variants={variants}
          className="z-50 absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 w-full p-5"
        >
          <div className="w-full rounded-2xl shadow-xl overflow-hidden ">
            <div className="absolute left-1/2 bottom-0 -translate-x-1/2 text-5xl text-red-700">
              <AiFillCloseCircle onClick={() => setSelectedImage(null)} />
            </div>
            <img src={selectedImage} alt="" />
          </div>
        </motion.div>
      </motion.div>
      <div className="relative p-3 h-full overflow-y-auto pt-14 pb-20 scrollbar-hide">
        <ProfileNyusss />
        <div className="font-semibold">
          <TypewriterComponent
            onInit={(typewriter) => {
              typewriter.typeString("Yustika Indah Maharani").start();
            }}
            options={{
              cursor: "",
              loop: false,
              autoStart: true,
            }}
          />
        </div>
        <div className="text-sm py-2">
          <TypewriterComponent
            onInit={(typewriter) => {
              typewriter
                .pauseFor(3500)
                .typeString("<p>Bidadari dari Slogo, Tanon, Sragen</p>")
                .pauseFor(500)
                .typeString(
                  "<p>Wanita luar biasa yang ceria, cantik dan cerdas</p>"
                )
                .pauseFor(500)
                .typeString(
                  "<p>Hobi : ngambekin mimaaa tapi sayang banget sama mimaaanya</p>"
                )
                .pauseFor(500)
                .callFunction(() => setIsTypeEnd(true))
                .start();
            }}
            options={{
              cursor: "",
              loop: false,
              autoStart: true,
            }}
          />
          {/* <p>Bidadari dari Slogo, Tanon, Sragen</p>
          <p>Wanita luar biasa yang ceria, cantik dan cerdas</p>
          <p>Hobi : ngambekin mimaaa tapi sayang banget sama mimaaanya</p> */}
        </div>
        <motion.div
          initial={{ opacity: isTypeEnd ? 1 : 0 }}
          animate={{ opacity: isTypeEnd ? 1 : 0 }}
          // animate={{ opacity: 1 }}
          className=""
        >
          <FotonyaNyusss setSelectedImage={setSelectedImage} />
        </motion.div>
      </div>
    </TemplateNyusss>
  );
}

export default Indah;
