import React from 'react'

function Task(Task) {
    const TaskList = () => {   

        return(
            <div className='flex gap-2 md:gap-5 my-4 items-center'>
                <div>
                    <div className="w-12 h-12 xl:w-16 xl:h-16 rounded-lg bg-cyan-200"></div>
                </div>
                <div className="text-justify">
                    <h4 className='items-end font-sans font-light text-xs xl:text-lg'>Ini List </h4>    
                    <p className='items-end font-body font-light text-xs xl:text-base break-words w-full'>Lorem ipsum dolor sit amet consectetur.</p>                    
                </div>
            </div>
        )

    }
    return (                
        <div className="task-list h-56 xl:h-[380px] p-2 pt-0 mt-2 xl:p-3 md:pt-0 overflow-auto">
            {TaskList()}
            {TaskList()}
            {TaskList()}
            {TaskList()}
            {TaskList()}
            {TaskList()}
        </div>
        
    )
}

export default Task