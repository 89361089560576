import React from "react";

import TemplateNyusss from "./Components/TemplateNyusss";
import Logo from "./imgs/yustika-bima.png";
import { useState } from "react";
import { getJsonData, setJsonData } from "../GlobalFunction/JsonUtils";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";

function Nyuss() {
  const [isError, setIsError] = useState(false);

  const onSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const mimanyuss = {};
    const kunciHati = { mima: "mima", nyusss: "200423" };

    for (let entry of formData.entries()) {
      mimanyuss[entry[0].toLowerCase()] = entry[1].toLowerCase();
    }

    const benerNyusss = Object.keys(mimanyuss).every(
      (key) => mimanyuss[key] === kunciHati[key]
    );
    console.log(benerNyusss);
    if (benerNyusss) {
      setJsonData({ key: "nyusss", data: true });
      window.location.href = "/nyusss";
    } else {
      setIsError(true);
    }
  };

  if (getJsonData("nyusss")) {
    return <Navigate to="/nyusss" replace />;
  }

  return (
    <TemplateNyusss isShowNavbar={false}>
      <div className="h-full pb-20 flex flex-col justify-center items-center p-3 scrollbar-hide overflow-auto">
        <div className="w-5/12 my-5">
          <img src={Logo} alt="" className="" />
        </div>

        <form className="w-full px-2" onSubmit={(e) => onSubmit(e)}>
          <div className="flex flex-col w-full p-2 gap-3 items-center">
            <h2 className="text-center">
              Masuk ke hatinya Mima dulu ya Nyusss
            </h2>
            <div className="w-full border-2 border-pink-500 rounded-xl p-2">
              <input
                type="text"
                name="mima"
                placeholder="Sayangnya Nyusss"
                className="bg-transparent p-1 w-full focus:outline-none"
                // onChange={(e) => setMessage(e.target.value)}
              />
            </div>
            <div className="w-full border-2 border-pink-500 rounded-xl p-2">
              <input
                type="password"
                name="nyusss"
                placeholder="First date : ddmmyy"
                className="bg-transparent p-1 w-full focus:outline-none"
                // onChange={(e) => setMessage(e.target.value)}
              />
            </div>

            {isError && <h2>Peluk dulu biar dikasih hint</h2>}
            <button
              type="submit"
              className="w-full bg-gradient-to-br shadow-xl rounded-xl from-pink-500 to-red-500 h-full p-2 text-neutral-100 "
            >
              Verifikasi Nyusss
            </button>
          </div>
        </form>
      </div>
    </TemplateNyusss>
  );
}

export default Nyuss;
