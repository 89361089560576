import React from "react";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";

function TimelineCard({ title, deskripsi, date, tahun, tech }) {
  const { ref, inView } = useInView();
  const animation = useAnimation();
  useEffect(() => {
    if (inView) {
      animation.start({
        opacity: 1,
        y: -50,
        transition: {
          duration: 0.5,
        },
      });
    } else if (!inView) {
      animation.start({
        opacity: 0,
        y: 20,
      });
    }
  }, [inView]);

  return (
    <motion.li
      ref={ref}
      animate={animation}
      className="timeline-list shadow-xl shadow-sky-100 md:px-5 py-2 relative mt-8"
    >
      <div className="dot-timeline absolute -left-14 bottom-auto p-3 w-12 h-12 bg-primary rounded-full text-center shadow-xl shadow-sky-100">
        {tahun}
      </div>
      <h3 className="timeline-title font-sans font-medium">{title}</h3>
      <p className="date  my-2 font-body font-light text-sm">
        {" "}
        <span className="bg-blue-100 p-1 px-4 rounded-full shadow-lg">
          {date}
        </span>
      </p>
      <p className="timeline-deskripsi font-body font-light text-sm">
        {deskripsi}
      </p>
      <div className="language flex flex-wrap justify-center mt-6">
        {tech.map((ic, i) => {
          return (
            <div className="circle-icon" key={i}>
              {ic}
            </div>
          );
        })}
      </div>
    </motion.li>
  );
}

export default TimelineCard;
