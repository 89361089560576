import React from "react";
import { Navigate } from "react-router-dom";

function JagainNyusss({ isSignedIn, children }) {
  if (!isSignedIn) {
    console.log("hey");
    return <Navigate to="/nyusss/masukdulu" replace />;
  }
  return children;
}

export default JagainNyusss;
