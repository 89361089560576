import React, { useState } from 'react'
import { motion } from "framer-motion"
import {Link} from "react-scroll"

import { AiOutlineHome } from 'react-icons/ai';
import { BiCodeAlt } from 'react-icons/bi';
import { SiAboutdotme } from 'react-icons/si';
import { MdTaskAlt } from 'react-icons/md';
import { CgMenuGridO, CgClose } from 'react-icons/cg';

function Navbar() {

    // const ListMenu = (namamenu) =>{
    //     return <li key={namamenu} className='mx-3 hover:underline text-primary'><a href="#" className='px-5'>{namamenu}</a></li>;
    // }
    
    const menus = [
        {nama:'header', icon:<AiOutlineHome/>}, 
        {nama:'about', icon:<SiAboutdotme/>}, 
        {nama:'skill', icon:<BiCodeAlt/>}, 
        {nama:'project', icon:<MdTaskAlt/>}, 
    ];

    const variants = {
        open: { display: "block", opacity: 1, y: -10 },
        closed: { opacity: 0, y: 20 ,transitionEnd: {
            display: "none",
        },},
        hidden: { opacity: 0 },
    }
      

    const [active, setactive] = useState(false);
    return (
        <div id="navbar" className=''>            
            
            <div className="hidden fixed md:block z-50 md:-bottom-20 md:right-10 xl:-bottom-40 xl:right-20">
                <div className="flex flex-col">
                    {
                        menus.map((menu,i) => (                                     
                            <Link to={menu.nama} spy={true} smooth={true} key={i} className="cursor-pointer">
                                {/* <motion.div animate={{ y: -300 }} transition={{ duration: 0.5*(i+1)}} className="bg-nav4 circle-nav" >{menu.icon}</motion.div>                                 */}
                                <motion.div animate={{ y: -300 }} transition={{ delay: 0.2*(i+2), type: 'spring', damping: 13 }} className="bg-nav4 circle-nav" >{menu.icon}</motion.div>                                
                            </Link>
                        ))

                        
                    }                    
                    
                </div>
            </div>


            {/* { console.log(active) } */}
            <div className="md:hidden fixed flex justify-center w-10 h-10 z-50 left-0 bottom-5 right-0 ml-auto mr-auto bg-nav4 circle-nav items-center text-2xl" onClick={() => setactive(!active)}>{ active ? <CgClose/> : <CgMenuGridO/> } </div>
            <div className={`fixed z-50 flex md:hidden justify-center left-0 right-0 ml-auto mr-auto bottom-20`}>            
                {/* <div className={`${active ? "block" : "hidden" } navbar-mobile flex-row`}> */}
                <div className={`flex navbar-mobile flex-col`}>
                    {
                        menus.map((menu,i) => (                            
                            // <a href={`#${menu.nama}`} onClick={() => setactive(!active)} key={i}>
                            <Link to={menu.nama} spy={true} smooth={true} onClick={() => setactive(!active)} key={i}>
                                {/* <motion.div animate={{ y: -10 }} transition={{ duration: 0.5*(i+1) }} className={`${active ? "block" : "hidden"} bg-nav4 circle-nav`} ></motion.div> */}
                                {/* <motion.div initial="hidden" animate={active ? "open" : "closed" } transition={{ duration: 0.2*(i+1) }} variants={variants} className="bg-nav4 circle-nav" >{menu.icon}</motion.div> */}
                                <motion.div initial="hidden" animate={active ? "open" : "closed" } transition={{ delay: 0.1*(i), type: 'spring', damping: 10  }} variants={variants} className="bg-nav4 circle-nav flex items-center justify-center" >{menu.icon}</motion.div>
                            </Link>
                        ))
                    }                    
                    
                </div>
            </div>            
        </div>
    )
}

export default Navbar