import React from "react";
import { MdDarkMode, MdLightMode } from "react-icons/md";
import { SiLinkedin, SiTelegram, SiTwitter, SiWhatsapp } from "react-icons/si";
import {
  LinkedinShareButton,
  TelegramShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { useStateContext } from "../../Contexts/ContextProvider";

function ShareWiki(props) {
  // const [isDark, setIsDark] = useState(false);
  const { isDark, setIsDark } = useStateContext(false);

  const changeTheme = (e) => {
    const _isDark = e.target.checked;
    setIsDark(_isDark);
  };

  return (
    <div className="flex-none border-t-2 py-2">
      <div className="flex justify-between w-full px-2">
        <WhatsappShareButton url={props.url} className="px-2">
          <SiWhatsapp className="wiki-icon" />
        </WhatsappShareButton>
        <TelegramShareButton url={props.url} className="px-2">
          <SiTelegram className="wiki-icon" />
        </TelegramShareButton>
        <TwitterShareButton url={props.url} className="px-2">
          <SiTwitter className="wiki-icon" />
        </TwitterShareButton>
        <LinkedinShareButton url={props.url} className="px-2">
          <SiLinkedin className="wiki-icon" />
        </LinkedinShareButton>

        <label className="swap swap-rotate border-l-2 px-2">
          <input
            type="checkbox"
            checked={isDark}
            onChange={(e) => changeTheme(e)}
          />

          <MdLightMode className="swap-on fill-current wiki-icon" />
          <MdDarkMode className="swap-off fill-current wiki-icon" />
        </label>

        {props.children}
      </div>
    </div>
  );
}

export default ShareWiki;
