import React from "react";
import { Bar } from "react-chartjs-2";

function BarChart({ chartData }) {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: chartData.title,
      },
    },
  };

  return <Bar className="" data={chartData} options={options} />;
}

export default BarChart;
