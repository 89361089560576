import React, { useState } from "react";
import { useStateContext } from "../Contexts/ContextProvider";
import TextEditorWiki from "./Component/TextEditorWiki";

function EditorWiki() {
  // const { isDark, setIsDark } = useStateContext(false);
  const { isDark } = useStateContext(false);
  const [convertText, setConvertText] = useState();
  const [highlight, setHighlight] = useState();

  const convert = (e) => {
    console.log(e);
    const encoded = e;
    // const encoded = addSlashes(e);
    setConvertText(encoded);
  };

  return (
    <div
      data-theme={isDark ? "night" : "cupcake"}
      className="transition min-h-screen"
    >
      <div className="flex flex-col justify-center items-center min-h-screen px-20 py-5">
        <div className="pb-4">
          <h1 className="font-black text-3xl">Editor</h1>
        </div>
        <div className="flex flex-col items-center gap-5 bg-white rounded-3xl p-10 shadow-2xl shadow-slate-300 w-full">
          <form action="" className="w-full flex flex-col gap-4">
            <div className="w-full">
              <input
                type="text"
                placeholder="Type here"
                className="input w-full "
              />
            </div>
            <div className="flex gap-5 w-full">
              <div className="w-full rounded-2xl overflow-hidden h-80">
                <TextEditorWiki onChange={convert} />
              </div>
              {/* <div className="divider divider-horizontal">
                <SiConvertio className="w-20 h-20" />
              </div> */}
              {/* <div className="w-6/12 h-80">
                <CodeViewer
                  code={convertText}
                  language={"jsx"}
                  highlight={highlight}
                />
              </div> */}
            </div>
            <div className="flex gap-5 w-full">
              <select className="select w-1/2">
                <option disabled selected>
                  Pick your favorite Simpson
                </option>
                <option>Homer</option>
                <option>Marge</option>
                <option>Bart</option>
                <option>Lisa</option>
                <option>Maggie</option>
              </select>
              <input
                type="text"
                onChange={(e) => setHighlight(e.target.value)}
                placeholder="Line Highlight"
                className="input w-1/2"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EditorWiki;
