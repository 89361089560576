import Lottie from "lottie-react";
import React, { useEffect, useRef, useState } from "react";
import TypewriterComponent from "typewriter-effect";
import TemplateNyusss from "./Components/TemplateNyusss";
import Kiss from "./animations/kiss love.json";
import Logo from "./imgs/yustika-bima.png";
import { motion } from "framer-motion";

function InfoNyusss() {
  const [isTypeEnd, setIsTypeEnd] = useState(false);

  const variants = {
    visible: {
      opacity: 1,
      scale: 1,
    },
    hidden: {
      opacity: 0,
      scale: 0,
    },
  };
  const transition = {
    delay: 0.5,
    type: "spring",
    ease: [0, 0.71, 0.2, 1.01],
    damping: 5,
    stiffness: 100,
  };
  return (
    <TemplateNyusss>
      <div className="h-full pb-20 flex flex-col justify-center items-center p-3 scrollbar-hide overflow-auto">
        <div className="w-5/12 my-5">
          <img src={Logo} alt="" className="" />
        </div>
        <div className="p-2 w-full text-center">
          <motion.div
            className="px-2"
            initial="hidden"
            animate={"visible"}
            variants={variants}
            transition={transition}
          >
            <Lottie animationData={Kiss} loop={true} />
          </motion.div>
          <div className="text-gray-500 py-5">
            <TypewriterComponent
              onInit={(typewriter) => {
                typewriter
                  .typeString(
                    "<p>App ini dibuat dengan cinta untuk wanita spesial yang selama ini ku cari.</p>"
                  )
                  .pauseFor(500)
                  .typeString(
                    "<p>Selamat ulang tahun sayangku, kasihku, cintaku, cantiku, enyusssku, calon istri idamanku.</p>"
                  )
                  .pauseFor(500)
                  .typeString("Yustika Indah Maharani ")
                  .pauseFor(1000)
                  .typeString("Bima, hehe ada tambahannya")
                  .callFunction(() => setIsTypeEnd(true))
                  .start();
              }}
              options={{
                cursor: "",
                loop: false,
                autoStart: true,
              }}
            />
          </div>

          <motion.h3
            className="font-bold text-xl text-red-800"
            initial="hidden"
            animate={isTypeEnd ? "visible" : "hidden"}
            variants={variants}
            transition={transition}
          >
            I Love You Enyusss ku
          </motion.h3>
        </div>
      </div>
    </TemplateNyusss>
  );
}

export default InfoNyusss;
