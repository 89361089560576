import { motion } from "framer-motion";
import React from "react";
import {
  AiFillExclamationCircle,
  AiFillHome,
  AiOutlineExclamation,
  AiOutlineHome,
  AiOutlineSearch,
} from "react-icons/ai";
import { BsSearchHeartFill } from "react-icons/bs";
import { Link, useLocation } from "react-router-dom";
import SEOMeta from "../../GlobalComponents/SEOMeta";
// import Mima from "../imgs/mima.jpg";
import Nyusss from "../imgs/galery/nyusss3.jpg";
import Love from "../animations/love.json";
import Lottie from "lottie-react";

function AnimatedMenu({ isSelected, children }) {
  return (
    <motion.div
      animate={{ scale: isSelected ? 1.2 : 1 }}
      initial={{ scale: 1, opacity: 1 }}
      transition={{ duration: 0.5, type: "spring", damping: 8 }}
      exit={{ scale: 1 }}
      className=""
    >
      {children}
    </motion.div>
  );
}

function TemplateNyusss({ children, isShowNavbar = true }) {
  const location = useLocation();
  const menus = [
    {
      path: "/nyusss",
      icon: <AiOutlineHome className="w-6 h-6" />,
      seletedIcon: <AiFillHome className="w-6 h-6" />,
    },
    {
      path: "/nyusss/yustika",
      icon: <AiOutlineSearch className="w-6 h-6" />,
      seletedIcon: <BsSearchHeartFill className="w-6 h-6" />,
    },
    {
      path: "/nyusss/indah",
      icon: <AiOutlineExclamation className="w-6 h-6" />,
      seletedIcon: <AiFillExclamationCircle className="w-6 h-6" />,
    },
  ];

  // useEffect(() => {
  //   console.log(location);
  //   console.log(getJsonData("nyusss"));
  // });
  return (
    <div
      data-theme={"cupcake"}
      className="transition nyusss-h flex justify-center items-center p-3"
    >
      <SEOMeta
        description="Yustika Indah Maharani 25th Birthday"
        name="Mima"
        thumbnail={Nyusss}
        title="Nyusss nya Mima"
        type="website"
      />
      <div className="hidden sm:block flex-auto">
        <Lottie animationData={Love} loop={true} />
      </div>
      <div className="relative flex-none h-full w-full sm:w-96 shadow-2xl rounded-xl overflow-hidden">
        {children}

        <div
          className={`${
            isShowNavbar ? "absolute" : "hidden"
          } bottom-0 w-full p-2 bg-white shadow-xl`}
        >
          <div className="flex justify-between w-full py-1 px-2 items-center">
            {menus.map((menu, index) => {
              const isSelected = location.pathname === menu.path;
              return (
                <AnimatedMenu key={index} isSelected={isSelected}>
                  <Link to={menu.path} replace className="text-red-800">
                    {isSelected ? menu.seletedIcon : menu.icon}
                  </Link>
                </AnimatedMenu>
              );
            })}
            <AnimatedMenu isSelected={location.pathname === "/nyusss/maharani"}>
              <Link to={"/nyusss/maharani"} replace>
                <img
                  src={Nyusss}
                  alt=""
                  className="w-6 h-6 object-cover rounded-full"
                />
              </Link>
            </AnimatedMenu>
          </div>
        </div>
      </div>
      <div className="hidden sm:block flex-auto">
        <Lottie animationData={Love} loop={true} />
      </div>
    </div>
  );
}

export default TemplateNyusss;
