import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Admin from "./Admin/Admin";
import ImageCompressor from "./Compressor/ImageCompressor";
import Base64Converter from "./Converter/Image/Base64Converter";
import Dashboard from "./Dashboard/Dashboard";
import MusicAdmin from "./MusicAdmin/MusicAdmin";
import Indah from "./Nyuss/Indah";
import Yustika from "./Nyuss/Yustika";
import EditorWiki from "./Wiki/EditorWiki";
import Wiki from "./Wiki/Wiki";
import SearchYustika from "./Nyuss/SearchYustika";
import { AnimatePresence } from "framer-motion";
import InfoNyusss from "./Nyuss/InfoNyusss";
import NotifNyusss from "./Nyuss/NotifNyusss";
import MessageNyusss from "./Nyuss/MessageNyusss";
import Nyuss from "./Nyuss/Nyuss";
import JagainNyusss from "./Nyuss/Components/JagainNyusss";
import { getLocalStorageData } from "./GlobalFunction/LocalStorageUtils";
import { getJsonData } from "./GlobalFunction/JsonUtils";
import { HelmetProvider } from "react-helmet-async";

function LocationProvider({ children }) {
  return <AnimatePresence exitBeforeEnter>{children}</AnimatePresence>;
}

function MyRoutes() {
  const location = useLocation();
  const isEnyusss = () => {
    return getJsonData("nyusss");
  };
  return (
    <LocationProvider>
      <Routes key={location.pathname} location={location}>
        <Route path="/" element={<Dashboard />}></Route>
        <Route path="/admin" element={<Admin />}></Route>
        <Route path="/musicdashboard" element={<MusicAdmin />}></Route>
        <Route path="wiki">
          <Route index={true} element={<Wiki />} />
          <Route path="editor" element={<EditorWiki />} />
        </Route>
        <Route path="converter">
          <Route index={false} />
          <Route path="image">
            <Route index={false} />
            <Route path="base64" element={<Base64Converter />} />
          </Route>
        </Route>
        <Route path="compressor">
          <Route index={false} />
          <Route path="image" element={<ImageCompressor />} />
        </Route>

        <Route path="nyusss">
          <Route
            index={true}
            element={
              <JagainNyusss isSignedIn={isEnyusss()}>
                <Yustika />
              </JagainNyusss>
            }
          />

          <Route
            path="yustika"
            element={
              <JagainNyusss isSignedIn={isEnyusss()}>
                <SearchYustika />
              </JagainNyusss>
            }
          />
          <Route
            path="indah"
            element={
              <JagainNyusss isSignedIn={isEnyusss()}>
                <InfoNyusss />
              </JagainNyusss>
            }
          />
          <Route
            path="maharani"
            element={
              <JagainNyusss isSignedIn={isEnyusss()}>
                <Indah />
              </JagainNyusss>
            }
          />
          <Route
            path="notif"
            element={
              <JagainNyusss isSignedIn={isEnyusss()}>
                <NotifNyusss />
              </JagainNyusss>
            }
          />
          <Route
            path="pesan"
            element={
              <JagainNyusss isSignedIn={isEnyusss()}>
                <MessageNyusss />
              </JagainNyusss>
            }
          />
          <Route path="masukdulu" element={<Nyuss />} />
        </Route>

        {/* <Route path="/chat" element={<Chat/>}></Route> */}
      </Routes>
    </LocationProvider>
  );
}

function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <MyRoutes />
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
