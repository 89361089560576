export function setJsonData({ key, data }) {
  sessionStorage.setItem(key, JSON.stringify(data));
}

export function getJsonData(key) {
  return JSON.parse(sessionStorage.getItem(key));
}

export function removeJsonData(key) {
  sessionStorage.removeItem(key);
}
