import React, { useEffect, useRef, useState } from "react";
import TemplateNyusss from "./Components/TemplateNyusss";
import Mima from "./imgs/mima.jpg";
import Nyusss from "./imgs/nyuss3.jpg";
import { Link } from "react-router-dom";
import { motion, useInView } from "framer-motion";
import { AiFillHome, AiOutlineSend } from "react-icons/ai";

function MessageCard({ message, rootView, isMima = true }) {
  const ref = useRef(null);
  const isInview = useInView(ref, { root: rootView, once: false });
  const variants = {
    open: { display: "block", opacity: 1, scale: 1 },
    close: {
      opacity: 0,
      scale: 0,
    },
    hidden: { opacity: 0, scale: 0 },
  };
  return (
    <div className={`flex ${isMima ? "" : "flex-row-reverse"}`}>
      <motion.div
        ref={ref}
        initial="hidden"
        animate={isInview ? "open" : "close"}
        // animate={"open"}
        transition={{
          delay: 0.1,
          type: "spring",
          damping: 8,
        }}
        variants={variants}
        className={`flex ${
          isMima ? "" : "flex-row-reverse"
        } justify-between items-center shadow-xl rounded-2xl p-2 gap-1 w-10/12`}
      >
        <div className="flex-none w-12 h-12">
          <img
            src={isMima ? Mima : Nyusss}
            alt=""
            className="w-12 h-12 object-cover rounded-full"
          />
        </div>
        <div className="flex flex-col flex-auto gap-1">
          <p className="text-xs font-medium">{message}</p>
        </div>
      </motion.div>
      <div className="w-2/12"></div>
    </div>
  );
}
function MessageNyusss() {
  const _questions = [
    "Nyusss aku sayang banget sama Nyusss",
    "Nyusss jangan takut lagi buat menjalin hubungan ya",
    "Aku nggak nakal kok",
    "Aku nggak bakal nyakitin Nyusss kok",
    "Nyusss jangan ngambek lagi ya sama mima",
    "Mima takut kalau Nyusssnya ngambek",
    "Nyusss yang semangat ya",
    "I Love you Nyusss Ku",
    "PELUK DONG MIMANYA",
  ];
  const _answers = ["Iya Mima, sini peluk cium"];
  const [message, setMessage] = useState("");
  const [questions, setQuestions] = useState(_questions);
  const [answers, setAnswers] = useState(_answers);
  const messageFieldRef = useRef(null);
  const containerRef = useRef(null);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [answers]);

  const sendMessage = () => {
    if (message !== "") {
      console.log(message);
      setAnswers((answer) => [...answer, message]);
      messageFieldRef.current.focus();
      setMessage("");
    }
    // scrollToBottom();
    // answers.push(message);
  };

  return (
    <TemplateNyusss isShowNavbar={false}>
      <div className="flex gap-1 absolute z-40 top-0 p-2 bg-white shadow-xl w-full items-center">
        <div className="flex-none w-12 h-12">
          <img
            src={Mima}
            alt=""
            className="w-12 h-12 object-cover rounded-full"
          />
        </div>
        <h1 className="text-2xl font-extrabold p-3">Pesan Buat Nyusss</h1>
      </div>
      <div
        ref={containerRef}
        className="h-full overflow-y-auto pt-20 pb-16 flex flex-col p-3 scrollbar-hide"
      >
        <ul>
          {questions.map((question, index) => {
            return (
              <li key={index} className="p-2">
                <MessageCard
                  message={question}
                  rootView={containerRef}
                  isMima={true}
                />
              </li>
            );
          })}
          {answers.map((answer, index) => {
            return (
              <li key={index} className="p-2">
                <MessageCard
                  message={answer}
                  rootView={containerRef}
                  isMima={false}
                />
              </li>
            );
          })}
        </ul>
        <div ref={messagesEndRef} />
      </div>

      <div className="absolute bottom-0 w-full p-2 bg-white">
        <div className="flex w-full p-2 gap-1 items-center">
          <Link to={"/nyusss/"} replace>
            <button
              onClick={sendMessage}
              className="flex-none bg-gradient-to-br shadow-xl rounded-xl from-pink-500 to-red-500 h-full p-2 text-neutral-100 "
            >
              <AiFillHome />
            </button>
          </Link>
          <div className="flex-auto ">
            <input
              ref={messageFieldRef}
              type="text"
              placeholder="pesan buat mima..."
              className="bg-transparent p-1 w-full focus:outline-none"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <button
            onClick={sendMessage}
            className="flex-none bg-gradient-to-br shadow-xl rounded-xl from-pink-500 to-red-500 h-full p-2 text-neutral-100 "
          >
            <AiOutlineSend />
          </button>
        </div>
      </div>
    </TemplateNyusss>
  );
}

export default MessageNyusss;
