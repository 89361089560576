import React, { useRef } from "react";
import "../GlobalStyle/slick.css";
import cover2 from "../MusicAdmin/Img/toto.jpeg";
import Slider from "react-slick";

function SliderImage({ children, size }) {
  const sliderRef = useRef(null);
  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: size < 4 ? size : 4,
    slidesToScroll: size < 4 ? size : 4,
    // centerMode: true,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      // {
      //   breakpoint: 800,
      //   settings: {
      //     slidesToShow: 2,
      //     slidesToScroll: 2,
      //   }
      // },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
  return (
    <Slider ref={sliderRef} {...settings}>
      {children}
    </Slider>
  );
}

export default SliderImage;
