import React from 'react'
import { motion } from "framer-motion";
import { BiSearch } from 'react-icons/bi';

function HeaderAdmin() {
    return (
        <header className='container-music'>
            <form action="">
                <div className="flex relative px-3 md:px-0">
                    <input type="text" name="search" id="searchInput" className='outline-none px-4 py-3 text-base font-body font-normal border-none shadow-xl rounded-full min-w-full' placeholder='Search . . .'/>
                    <button type="submit" className='absolute right-4 md:right-1 top-1/2 transform -translate-y-1/2 font-xl border-l-2 boder-hero bg-gradient-to-tr from-indigo-200 to-orange-100 h-10 w-10 rounded-full flex items-center justify-center'><BiSearch/></button>
                </div>
            </form>
        </header>
    )
}

export default HeaderAdmin