import React from "react";
import { useState } from "react";

function SearchBarWiki(props) {
  const [searchValue, setSearchValue] = useState();
  const [isVisibleSearchInfo, setIsVisibleSearchInfo] = useState(false);

  const SearchOnChange = (e) => {
    const value = e.target.value;
    props.onChange(value);
    setSearchValue(value);
    if (value !== "") setIsVisibleSearchInfo(true);
    else setIsVisibleSearchInfo(false);
  };

  return (
    <div className="flex-none border-b-2 px-2 py-2">
      {/* <label className="label cursor-pointer">
        <span className="label-text px-2">Dark Mode</span>
        <input type="checkbox" className="toggle" />
      </label> */}
      <input
        className="input input-ghost w-full max-w-xs text-sm rounded-xl"
        type="text"
        placeholder="Cari Disini"
        onChange={(e) => SearchOnChange(e)}
      />
      <p
        className={` ${
          isVisibleSearchInfo ? "block" : "hidden"
        } text-info text-xs font-light pt-4 text-right`}
      >
        enter untuk mencari
      </p>
    </div>
  );
}

export default SearchBarWiki;
