import React from 'react'
import sampleImg1 from '../Img/aerosmith_logo.jpg'
import sampleImg2 from '../Img/bon-jovi-logo.png'
import sampleImg3 from '../Img/dark-side-of-the-moon.jpg'
import sampleImg4 from '../Img/gary-moore.jpeg'
import sampleImg5 from '../Img/queen.jpg'
import sampleImg6 from '../Img/The-Beatles-Logo.jpg'
import sampleImg7 from '../Img/toto_logo.jpeg'
import sampleImg8 from '../Img/stevie wonder.jpg'
import sampleImg9 from '../Img/the police.jpg'
import sampleImg10 from '../Img/dire straits.jpg'
import Slider from "react-slick";

function ClipMusic() {    
    const settings = {
        arrows: false,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 10,
        slidesToScroll: 4,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 8,
                // slidesToScroll: 3,
              }
            },
            {
              breakpoint: 800,
              settings: {
                slidesToShow: 6,
                // slidesToScroll: 2,                
              }
            },
            {
              breakpoint: 720,
              settings: {
                slidesToShow: 4,
                // slidesToScroll: 2
              }
            }
          ]
      };
//       import sampleImg4 from '../Img/gary-moore.jpeg'
// import sampleImg5 from '../Img/queen.jpg'
// import sampleImg6 from '../Img/The-Beatles-Logo.jpg'
// import sampleImg7 from '../Img/toto_logo.jpeg'
// import sampleImg8 from '../Img/stevie wonder.jpg'
// import sampleImg9 from '../Img/the police.jpg'
// import sampleImg10 from '../Img/dire straits.jpg'
    const listClip = [
        {id: 1, artist: 'Aerosmith', title: 'Lorem ipsum dolor sit amet.', img: sampleImg1},
        {id: 2, artist: 'Bon Jovi', title: 'Lorem ipsum dolor sit amet.', img: sampleImg2},
        {id: 3, artist: 'Pink FLoyd', title: 'Lorem ipsum dolor sit amet.', img: sampleImg3},
        {id: 4, artist: 'Gary Moore', title: 'Lorem ipsum dolor sit amet.', img: sampleImg4},
        {id: 5, artist: 'Queen', title: 'Lorem ipsum dolor sit amet.', img: sampleImg5},
        {id: 6, artist: 'The Beatles', title: 'Lorem ipsum dolor sit amet.', img: sampleImg6},
        {id: 7, artist: 'TOTO', title: 'Lorem ipsum dolor sit amet.', img: sampleImg7},
        {id: 8, artist: 'Stevie Wonder', title: 'Lorem ipsum dolor sit amet.', img: sampleImg8},
        {id: 9, artist: 'The Police', title: 'Lorem ipsum dolor sit amet.', img: sampleImg9},
        {id: 10, artist: 'Dire Straits', title: 'Lorem ipsum dolor sit amet.', img: sampleImg10},                
        {id: 1, artist: 'Aerosmith', title: 'Lorem ipsum dolor sit amet.', img: sampleImg1},
        {id: 2, artist: 'Bon Jovi', title: 'Lorem ipsum dolor sit amet.', img: sampleImg2},
        {id: 3, artist: 'Pink FLoyd', title: 'Lorem ipsum dolor sit amet.', img: sampleImg3},
        {id: 4, artist: 'Gary Moore', title: 'Lorem ipsum dolor sit amet.', img: sampleImg4},
    ]

    return (
        <div className='container-music'>
            <h3 className="title-section">Audio Clip</h3>
            {/* <div className="flex p-2 gap-6 overflow-auto scrollbar bg-admin justify-between"> */}
            <div className="w-full">
                <Slider {...settings}>
                    {listClip.map((clip, i) => (
                        <div key={i} className="flex flex-col justify-center items-center gap-1">
                            <div className="w-14 h-14">
                                <img src={clip.img} alt="" className='circle-clip'/>
                            </div>
                            <p className='text-xs'>{clip.artist}</p>
                        </div>                    
                    ))}
                </Slider>
            </div>
                
            {/* </div>             */}
        </div>
        
        // <div className='container-music'>
        //     <h3 className="title-section">Audio Clip</h3>
        //     <div className="flex p-2 gap-6 overflow-auto scrollbar bg-admin justify-between">
        //         {listClip.map((clip, i) => (
        //             <div key={i} className="flex flex-col justify-center items-center gap-1">
        //                 <div className="w-14 h-14">
        //                     <img src={sampleImg} alt="" className='circle-clip'/>
        //                 </div>
        //                 <p className='text-xs'>{clip.artist}</p>
        //             </div>                    
        //         ))}
        //     </div>            
        // </div>
    )
}

export default ClipMusic