import React from 'react'
import { FaGithub, FaInstagram, FaLinkedin } from 'react-icons/fa'

function Footer() {
    return (
        <div id="footer" className='bg-hero py-8 drop-shadow-2xl'>
            <div className="container text-primary text-center">
                <div className="flex-row">
                    <div className='social-media-footer text-2xl flex gap-8 justify-center'>
                        <a href="https://github.com/f0bima" target="_blank" rel="noopener noreferrer">
                            <FaGithub />
                        </a>
                        <a href="https://www.instagram.com/f0bima/" target="_blank" rel="noopener noreferrer">
                            <FaInstagram />
                        </a>
                        <a href="https://www.linkedin.com/in/fauzan-bima-putra-kencana/" target="_blank" rel="noopener noreferrer">
                            <FaLinkedin/>
                        </a>                    
                    </div>
                
                    <h2 className='mt-8'>Design by Fauzan Bima</h2>
                </div>
                
            </div>
        </div>
    )
}

export default Footer