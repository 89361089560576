import React from "react";

// import F0bima from '../Img/f0bima_about.jpg'
import { FaJava, FaReact } from "react-icons/fa";
import { GrMysql } from "react-icons/gr";
import {
  SiAndroidstudio,
  SiArduino,
  SiBootstrap,
  SiPython,
  SiSpringboot,
} from "react-icons/si";
import TimelineCard from "./TimelineCard";

const timelines = [
  {
    tahun: "22",
    date: "Des 2022 - Aug 2023",
    title: "Channel Delivery System Specialist Bank Mega",
    deskripsi:
      "Mengembangkan aplikasi pelayanan CS dan Teller untuk kantor cabang bank Mega",
    tech: [<SiSpringboot />, <FaReact />, <SiBootstrap />, <GrMysql />],
  },
  {
    tahun: "21",
    date: "Jul 2021 - Nov 2022",
    title: "Lead Firmware Engineer Neurabot",
    deskripsi:
      "Membuat hardware slide scanner untuk mengambil gambar objek mikroskopik secara keseluruhan (whole slide image). Proses scanning area 20x20 mm memakan waktu 20 menit.",
    tech: [<SiArduino />, <SiPython />],
  },
  {
    tahun: "19",
    date: "Jul 2019 - Jul 2021",
    title: "Android Developer Neurabot",
    deskripsi:
      "Membuat aplikasi android untuk mengambil gambar objek mikroskopik dan menjahit gambar tersebut menjadi gambar utuh dengan resolusi tinggi. Menghubungkan java android studio dengan OpenCV native C++.",
    tech: [<SiAndroidstudio />, <FaJava />],
  },
  {
    tahun: "19",
    date: "Aug 2019 - Jan 2021",
    title: "Ketua Tim Robotika UNS",
    deskripsi:
      "Memimpin UKM Tim Robotika UNS untuk mengikuti kompetisi Kontes Robot Indonesia 2020 era Pandemi. Meloloskan 3 Tim ke kompetisi regional dan 1 Tim ke tingkat nasional.",
    tech: [<SiArduino />, <SiPython />],
  },
];

function Timeline() {
  return (
    <div id="about" className="container mt-8 px-8 xl:px-60">
      <h2 className="font-sans font-medium text-2xl text-center mb-5">About</h2>
      <div className="flex-row md:flex md:justify-evenly">
        <div className="timeline-image flex-row flex-1 justify-center self-center md:mr-10 py-10">
          {/* <img src={F0bima} className="w-[300px]" alt="Fauzan Bima"/> */}
          <div className="about-description font-body font-light md:text-right">
            <p>
              Hallo, saya <b>Fauzan Bima.</b>
            </p>
            <p>
              Saya seorang software developer. Saya memiliki ketertarikan dalam
              bidang otomasi, website dan mobile
            </p>

            <p>
              Saat ini saya fokus sebagai frontend developer baik website maupun
              mobile.
            </p>
          </div>
        </div>
        <div className="timeline-line flex-1 border-l-8 border-y-neutral-800 pl-7 py-8">
          <ul>
            {timelines.map((timeline, i) => (
              <TimelineCard
                key={i}
                tahun={timeline.tahun}
                date={timeline.date}
                title={timeline.title}
                deskripsi={timeline.deskripsi}
                tech={timeline.tech}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Timeline;
