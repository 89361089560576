import React from "react";
import {
  ZoomableGroup,
  ComposableMap,
  Geographies,
  Geography,
} from "react-simple-maps";
import geo from "../geo.json";

function Map({ setTooltipContent }) {
  //     const geoUrl =
  //   "https://raw.githubusercontent.com/deldersveld/topojson/master/world-countries.json";
  return (
    <div className="flex h-full items-center">
      <ComposableMap
        data-tip=""
        height={400}
        style={{ width: "100%", height: "100%" }}
        className="outline-none"
      >
        <ZoomableGroup center={[20, 8]} zoom={1}>
          <Geographies geography={geo}>
            {({ geographies }) =>
              geographies.map((geo) => (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  onMouseEnter={() => {
                    setTooltipContent(`${geo.properties.name}`);
                  }}
                  onMouseLeave={() => {
                    setTooltipContent("");
                  }}
                  style={{
                    default: {
                      height: "72px",
                      fill: "#D6D6DA",
                      outline: "none",
                    },
                    hover: {
                      fill: "#F53",
                      outline: "none",
                    },
                    pressed: {
                      fill: "#E42",
                      outline: "none",
                    },
                  }}
                />
              ))
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
    </div>
  );
}

export default Map;
