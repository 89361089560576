import React, { createContext, useContext, useState } from "react";

const StateContext = createContext();

const initialState = {};

export const ContextProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isOpenProfileWA, setIsOpenProfileWA] = useState(false);
  const [isDark, setIsDark] = useState(false);

  return (
    <StateContext.Provider
      value={{
        isOpen,
        setIsOpen,
        isOpenProfileWA,
        setIsOpenProfileWA,
        isDark,
        setIsDark,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
