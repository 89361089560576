import React from "react";
import { HiBookmark, HiThumbUp } from "react-icons/hi";

function FloatingActionButton() {
  return (
    <div className="z-20 fixed right-10 bottom-10">
      <ul className="flex flex-col justify-center items-center gap-5">
        <li>
          <button className="floating-action-button">
            <HiBookmark className="wiki-icon" />
          </button>
        </li>
        <li>
          <button className="floating-action-button">
            <HiThumbUp className="wiki-icon" />
          </button>
        </li>
      </ul>
    </div>
  );
}

export default FloatingActionButton;
