import React, { useEffect, useState } from "react";
import { useStateContext } from "../../Contexts/ContextProvider";
import DropFile from "../../GlobalComponents/DropFile";
import SliderImage from "../../GlobalComponents/SliderImage";
import cover2 from "../../MusicAdmin/Img/cover1.jpg";
import { IoCopy } from "react-icons/io5";
import { isEmpty } from "../../GlobalFunction/InputUtils";

function Base64Converter() {
  const [files, setFiles] = useState([]);
  const [base64Text, setBase64Text] = useState("");
  const { isDark, setIsDark } = useStateContext(false);

  useEffect(
    () => () => {
      files.forEach((file) => {
        URL.revokeObjectURL(file.url);
      });
      // objectToArray(files, "url");
    },
    [files]
  );

  // const objectToArray = (objectData, key) => {
  //   const myArray = Array.from(Object.values(objectData), (obj) => obj[key]);
  //   console.log(myArray);
  // };
  const fileToBase64 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => setBase64Text(reader.result);
    reader.onerror = (error) => console.error(error);
  };

  const copyToClipBoard = () => navigator.clipboard.writeText(base64Text);
  return (
    <div
      data-theme={isDark ? "night" : "cupcake"}
      className="transition min-h-screen flex justify-center items-center px-36 py-10"
    >
      <div className="rounded-3xl shadow-2xl shadow-slate-300 overflow-hidden w-full ">
        <div className={`${files.length === 0 ? "block" : "hidden"} p-10 `}>
          <DropFile files={files} setFiles={setFiles} />
        </div>

        <div
          className={`${
            files.length === 0 && "hidden"
          } flex flex-col justify-between items-center`}
        >
          <div className="flex gap-5 justify-center items-center px-10 py-2 w-full h-full">
            <div className="h-full bg-gray-200 rounded-2xl overflow-hidden">
              <img
                src={base64Text}
                color=""
                className={`${
                  isEmpty(base64Text) ? "hidden" : "block"
                } h-64 object-contain aspect-square`}
                alt=""
              />
              <div
                className={`${
                  isEmpty(base64Text) ? "block" : "hidden"
                } flex justify-center items-center h-64 object-contain aspect-square w-full`}
              >
                <p className="text-center">Select image to convert base64</p>
              </div>
            </div>
            <textarea
              className="textarea textarea-ghost w-full scrollbar rounded-2xl bg-gray-200 h-64"
              readOnly
              placeholder="Select image to convert base64"
              value={base64Text}
            ></textarea>
          </div>
          <button
            className="btn gap-2 hover:scale-105"
            onClick={copyToClipBoard}
          >
            <IoCopy />
            Copy Base64
          </button>
        </div>

        <div className="w-full items-center">
          {files.length > 0 && (
            <SliderImage size={files.length}>
              {files?.map((image, idx) => {
                return (
                  <div
                    key={idx}
                    className="flex items-center justify-center p-5"
                  >
                    <img
                      src={image.url}
                      alt={image.path}
                      className="object-cover w-44 popular-card aspect-[5/4]"
                      onClick={() => fileToBase64(image)}
                    />
                  </div>
                );
              })}
            </SliderImage>
          )}
        </div>
      </div>
    </div>
  );
}

export default Base64Converter;
