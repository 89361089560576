import React, { useState } from "react";
import { CgClose, CgMenuGridO } from "react-icons/cg";
import CollapsedMenu from "./CollapsedMenuWiki";
import ShareWiki from "./ShareWiki";
import SearchBarWiki from "./SearchBarWiki";
import WikiMenu from "../../DummyData/WikiMenu.json";

function SidebarWiki() {
  const [filteredMenu, setFilteredMenu] = useState(WikiMenu);
  const currentUrl = window.location.href;

  const Menu = () => {
    console.log(filteredMenu);
    return (
      <div className="flex-auto overflow-y-auto scrollbar">
        {filteredMenu.map((menu, index) => {
          return (
            <CollapsedMenu
              key={index}
              title={menu.category}
              menus={menu.menus}
            />
          );
        })}
      </div>
    );
  };

  const filterMenu = (_value) => {
    // flatFilter()
    const value = _value.toLowerCase();
    const filtered = WikiMenu.filter((menu) => {
      return menu.menus.some((item) =>
        item.title.toLowerCase().includes(value)
      );
    }).map((menu) => {
      return {
        category: menu.category,
        menus: menu.menus.filter((item) =>
          item.title.toLowerCase().includes(value)
        ),
      };
    });

    // const filtered = menus.filter((menu) =>
    //   menu.category.toLowerCase().includes(value.toLowerCase())
    // );

    console.log(filtered);
    setFilteredMenu(filtered);
  };

  return (
    <>
      <div className="hidden lg:block fixed z-20 h-screen pl-28 py-8 transition">
        <div className="flex flex-col justify-between w-64 h-full rounded-3xl wiki-card p-2">
          <SearchBarWiki onChange={filterMenu} />
          <Menu />
          <ShareWiki url={currentUrl} />
        </div>
      </div>

      <div className="lg:hidden fixed bottom-8 right-8 z-50">
        <div className="bg-nav4 circle-nav w-12 h-12 flex items-center justify-center shadow-xl">
          <label htmlFor="mobile-menu-btn">
            <CgMenuGridO className="fill-current w-5 h-5" />
          </label>
        </div>

        <input type="checkbox" id="mobile-menu-btn" className="modal-toggle" />

        <div className="modal modal-bottom sm:modal-middle">
          <div className="modal-box flex flex-col justify-between h-full rounded-t-3xl wiki-card p-2">
            <SearchBarWiki onChange={filterMenu} />
            <Menu />
            <ShareWiki url={currentUrl}>
              <label htmlFor="mobile-menu-btn" className="border-l-2 px-2">
                <CgClose className="fill-current w-7 h-7" />
              </label>
            </ShareWiki>
          </div>
        </div>

        {/* <div className="bg-nav4 circle-nav flex items-center justify-center">
          <label className="swap swap-rotate">
            <input
              type="checkbox"
              checked={isMobileMenuOpen}
              onChange={(e) => setIsMobileMenuOpen(e.target.checked)}
            />

            <CgMenuGridO className="swap-off fill-current w-5 h-5" />
            <CgClose className="swap-on fill-current w-5 h-5" />
          </label>
        </div> */}
      </div>
    </>
  );
}

export default SidebarWiki;
