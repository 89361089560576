import { motion } from "framer-motion";
import React, { useState } from "react";
import { BiHome } from "react-icons/bi";
import { useMediaQuery } from "react-responsive";
import ReactTooltip from "react-tooltip";
import { useStateContext } from "../Contexts/ContextProvider";
import BarChart from "./Chart/BarChart";
import PolarChart from "./Chart/PolarChart";
import HeaderAdmin from "./Component/HeaderAdmin";
import Map from "./Component/Map";
import NavbarAdmin from "./Component/NavbarAdmin";
import Task from "./Component/Task";
import { BarData } from "./Data/BarData";

function Admin() {
  // const [isOpen, setIsOpen] = useState(true);
  const isBigScreen = useMediaQuery({ query: "(min-width: 1024px)" });
  const { isOpen, setIsOpen } = useStateContext();
  const [content, setContent] = useState("");
  const [barData, setBarData] = useState({
    labels: BarData.map((data) => data.region),
    datasets: [
      {
        label: "Provit",
        data: BarData.map((data) => data.laba),
      },
    ],
    title: "Provit",
  });
  const [polarData, setPolarData] = useState({
    labels: ["Red", "Blue", "Yellow", "Green", "Purple"],
    datasets: [
      {
        label: "# of Votes",
        data: [42, 39, 43, 45, 22, 23],
        backgroundColor: [
          "rgba(255, 99, 132, 0.5)",
          "rgba(54, 162, 235, 0.5)",
          "rgba(255, 206, 86, 0.5)",
          "rgba(75, 192, 192, 0.5)",
          "rgba(153, 102, 255, 0.5)",
        ],
        borderWidth: 1,
      },
    ],
    title: "Voting",
  });

  return (
    <div className="bg-admin flex">
      <NavbarAdmin />
      <motion.div
        animate={{
          marginLeft: isOpen && isBigScreen ? "200px" : "60px",
          transition: { duration: 0.5, type: "spring", damping: "10" },
        }}
        className="content w-full min-h-screen  overflow-hidden"
      >
        <HeaderAdmin />
        <div className="container -mt-8 overflow-hidden">
          <div className="grid p-3 grid-cols-1 md:p-0 md:grid-cols-3 gap-3 xl:gap-5 lg:gap-3 md:gap-2">
            <div className="md:col-span-2 p-0 md:p-0">
              <div className="grid grid-cols-2 gap-2 md:grid-cols-4 xl:gap-5 md:gap-2">
                <div className="admin-card bg-indigo-50 admin-card-small">
                  <BiHome />
                  <h3 className="font-sans font-normal">Home</h3>
                </div>
                <div className="admin-card bg-orange-50 admin-card-small">
                  <BiHome />
                  <h3 className="font-sans font-normal">Home</h3>
                </div>
                <div className="admin-card bg-blue-50 admin-card-small">
                  <BiHome />
                  <h3 className="font-sans font-normal">Home</h3>
                </div>
                <div className="admin-card bg-amber-50 admin-card-small">
                  <BiHome />
                  <h3 className="font-sans font-normal">Home</h3>
                </div>
              </div>
            </div>
            <div className="admin-card row-span-2">
              <PolarChart chartData={polarData} />
            </div>
            <div className="admin-card md:col-span-2">
              <BarChart chartData={barData} />
            </div>
            <div className="admin-card md:col-span-2 p-0 overflow-hidden">
              <h3 className="text-center text-xl py-3 font-sans font-medium">
                Map
              </h3>
              <Map setTooltipContent={setContent} />
              <ReactTooltip>{content}</ReactTooltip>
            </div>
            {/* <div className="admin-card flex justify-center items-center"><CalendarAdmin/></div> */}
            <div className="admin-card w-full flex-row">
              <h3 className="text-center text-xl py-3 font-sans font-medium border-b-2">
                Task
              </h3>
              <Task />
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
}

export default Admin;
