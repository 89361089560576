import React from "react";
import { useDropzone } from "react-dropzone";
import { FaUpload } from "react-icons/fa";

function DropFile({ files, setFiles }) {
  // const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            url: URL.createObjectURL(file),
          })
        )
      );
    },
  });

  return (
    <div {...getRootProps({ className: "dropzone" })}>
      <input {...getInputProps()} />
      <div className="flex flex-col justify-center items-center gap-5 p-10 bg-slate-200 rounded-2xl border border-rose-200 border-dashed border-spacing-10 outline-dashed outline-offset-8 outline-1">
        <FaUpload className="w-32 h-32 text-gray-400" />
        <p>Drag 'n' drop some files here, or click to select files</p>
      </div>
    </div>
  );
}

export default DropFile;
