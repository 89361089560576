import React from "react";
import F0bima from "../Img/f0bima.jpg";
import { motion } from "framer-motion";
import { FaGithub, FaInstagram, FaLinkedin } from "react-icons/fa";

function Header() {
  const sosmed = [
    { link: "https://github.com/f0bima", icon: <FaGithub /> },
    { link: "https://www.instagram.com/f0bima/", icon: <FaInstagram /> },
    {
      link: "https://www.linkedin.com/in/fauzan-bima-putra-kencana/",
      icon: <FaLinkedin />,
    },
  ];

  return (
    <div
      id="header"
      className="bg-hero flex justify-around h-screen sm:h-[720px] items-center relative"
    >
      <div className="logo absolute bg-primary px-10 py-1 top-0 md:left-28 lg:left-56">
        <div className="font-body">f0bima</div>
      </div>
      <div id="foto">
        <img src={F0bima} className="w-[566px]" alt="Fauzan Bima" />
      </div>

      <div
        id="tagline"
        className="absolute flex-row bottom-40 justify-center md:flex md:bottom-auto md:gap-96 md:w-1/2"
      >
        <div className="left-tagline text-center md:text-right flex-1 md:text-4xl">
          <h2 className="text-white font-body font-thin">-Halo, saya</h2>
          <h1 className="font-sans font-bold text-primary">Fauzan Bima</h1>
        </div>
        <div className="text-center md:text-left right-tagline flex-1">
          <h2 className="font-sans font-bold text-primary md:text-4xl">
            Software Developer
          </h2>
          <p className="font-body text-white font-thin text-sm">
            React JS, NEXT JS, Python, Arduino
          </p>
        </div>
      </div>

      <div
        id="social-media"
        className="text-primary absolute bottom-32 sm:bottom-10 text-2xl flex gap-8"
      >
        {sosmed.map((sm, i) => (
          <motion.a
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 5 }}
            transition={{ duration: 0.2 * i + 1 }}
            href={sm.link}
            key={i}
            target="_blank"
            rel="noopener noreferrer"
          >
            {sm.icon}
          </motion.a>
        ))}
      </div>
    </div>
  );
}

export default Header;
