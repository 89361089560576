import React from "react";
import Logo from "../imgs/yustika-bima.png";
import { removeLocalStorageData } from "../../GlobalFunction/LocalStorageUtils";
import { BiLogOut } from "react-icons/bi";
import { IoLogOut } from "react-icons/io5";
import { removeJsonData } from "../../GlobalFunction/JsonUtils";

function HeaderIndah() {
  const logout = () => {
    removeJsonData("nyusss");
    window.location.href = "/nyusss/masukdulu";
  };
  return (
    <div className="absolute z-40 top-0 p-2 bg-white shadow-xl w-full h-14">
      <div className="absolute right-0 p-1 top-1/2 -translate-y-1/2">
        <IoLogOut className="text-3xl" onClick={logout} />
      </div>
      <div className="py-1 h-full w-full flex justify-center items-center">
        <img className="h-full" src={Logo} alt="" />
      </div>
    </div>
  );
}

export default HeaderIndah;
