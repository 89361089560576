import React from "react";
// import Nyusss from "../imgs/hasbulla2.jpg";
import Nyusss from "../imgs/nyuss3.jpg";
import { motion } from "framer-motion";
function ProfileNyusss() {
  const words = ["Cinta", "Kasih", "Sayang"];
  const variants = {
    visible: { display: "block", opacity: 1, scale: 1 },
    hidden: { opacity: 0, scale: 0 },
  };
  return (
    <div className="flex justify-between items-center">
      <motion.div
        initial="hidden"
        animate={"visible"}
        transition={{
          delay: 0.1,
          type: "spring",
          damping: 8,
        }}
        variants={variants}
        className="flex-1 p-2"
      >
        <div className="p-1 bg-black rounded-full aspect-square bg-gradient-to-tr from-pink-400 to-red-700">
          <img
            className="rounded-full object-cover aspect-square"
            src={Nyusss}
            alt=""
          />
        </div>
      </motion.div>
      {words.map((word, i) => {
        return (
          <motion.div
            initial="hidden"
            animate={"visible"}
            transition={{
              delay: 0.1 * (i + 1),
              type: "spring",
              damping: 8,
            }}
            variants={variants}
            key={i}
            className="flex-1"
          >
            <div className="flex flex-col items-center gap-2">
              <h3 className="text-3xl font-semibold">1</h3>
              <p>{word}</p>
            </div>
          </motion.div>
        );
      })}
    </div>
  );
}

export default ProfileNyusss;
