import { Editor } from "@monaco-editor/react";
import React from "react";

function TextEditorWiki(props) {
  const { onChange, value } = props;
  // const [code, setCode] = useState();

  const setValue = (e) => {
    onChange(e);
  };
  return (
    <Editor
      language="dart"
      theme="vs-dark"
      value={value}
      autoComplete={true}
      // options={{
      //   theme: "vs-dark",
      // }}
      onChange={(newValue, e) => setValue(newValue)}
    />
    // <CodeEditor
    //   className="rounded-3xl"
    //   value={value}
    //   language="js"
    //   data-color-mode="light"
    //   tabIndex={2}
    //   placeholder="Please enter JS code."
    //   // onChange={(evn) => setCode(evn.target.value)}
    //   onChange={(env) => setValue(env.target.value)}
    //   padding={15}
    //   style={{
    //     fontSize: 12,
    //     backgroundColor: "#f5f5f5",
    //     fontFamily:
    //       "ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace",
    //   }}
    // />
  );
}

export default TextEditorWiki;
