import React from "react";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";

function ProjectCard(project) {
  const { ref, inView } = useInView();
  const animation = useAnimation();
  useEffect(() => {
    if (inView) {
      animation.start({
        opacity: 1,
        y: -50,
        transition: {
          duration: 0.5,
        },
      });
    } else if (!inView) {
      animation.start({
        opacity: 0,
        y: 20,
      });
    }
  }, [inView]);
  return (
    <motion.div
      ref={ref}
      animate={animation}
      className="project-card shadow-lg relative pb-24 xl:pb-16 rounded-xl overflow-hidden"
      key={project.i}
    >
      <img
        src={project.img}
        className="aspect-video object-cover object-center"
        alt={project.judul}
      />
      <div className="content px-3 py-2 text-justify">
        <h3 className="project-tittle font-sans font-medium">
          {project.judul}
        </h3>
        <p className="project-desc font-body font-light">{project.desc}</p>
      </div>

      <div className="absolute w-full bottom-4">
        <div className="flex-row xl:flex xl:justify-between px-3">
          <div className="language flex flex-wrap justify-center">
            {project.tech.map((ic, i) => {
              return (
                <div className="circle-icon" key={i}>
                  {ic}
                </div>
              );
            })}
          </div>
          <div className="project-link text-center mt-5 xl:mt-3 xl:text-right">
            <a
              href={project.link}
              className="p-1 px-3 bg-primary rounded-full shadow-lg text-hero font-sans font-light hover:bg-hero hover:text-primary"
              target="_blank"
              rel="noopener noreferrer"
            >
              Goto Link
            </a>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default ProjectCard;
