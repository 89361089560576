import React from "react";
import { SiReact } from "react-icons/si";

export default function Breadcrumb(props) {
  return (
    <div className="flex-1">
      <div className="text-sm breadcrumbs">
        <ul>
          {props.data.map((dt, idx) => {
            return (
              <li key={idx}>
                <a href={dt.link}>
                  <SiReact />
                  {dt.title}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
