import React from "react";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";

import ArduinoLogo from "../Img/arduino.png";
import Gamepad from "../Img/gamepad.png";
import Server from "../Img/server.png";
import Phone from "../Img/phone.png";
import FrontEnd from "../Img/frontend.png";
import { FaReact, FaLaravel, FaJava, FaUnity } from "react-icons/fa";
import { DiCodeigniter } from "react-icons/di";
import {
  SiTailwindcss,
  SiAndroidstudio,
  SiArduino,
  SiPython,
  SiBootstrap,
  SiFlutter,
  SiNextdotjs,
} from "react-icons/si";
import { GrMysql } from "react-icons/gr";
// import {TbCSharp} from 'react-icons/tb'
import * as TB from "react-icons/tb";

function Skill() {
  const skills = [
    {
      title: "Hardware",
      desc: "Saya sering menggunakan Arduino dalam pengembangan hardware",
      img: ArduinoLogo,
      tech: [<SiArduino />, <SiPython />],
    },
    {
      title: "Back End",
      desc: "Memanfaatkan framework Laravel dalam pembuatan rest api dan sql database",
      img: Server,
      tech: [<FaLaravel />, <DiCodeigniter />, <GrMysql />],
    },
    {
      title: "Front end",
      desc: "Pembuatan interface website menggunakan react js  dan css framework tailwind / bootstrap",
      img: FrontEnd,
      tech: [<FaReact />, <SiTailwindcss />, <SiBootstrap />, <SiNextdotjs />],
    },
    {
      title: "Mobile",
      desc: "Pengembangan aplikasi android menggunakan java Android Studio",
      img: Phone,
      tech: [<SiAndroidstudio />, <FaJava />, <SiFlutter />],
    },
    // {
    //   title: "Game",
    //   desc: "Pengembangan Game menggunakan C# Unity",
    //   img: Gamepad,
    //   tech: [<FaUnity />, <TB.TbBrandCSharp />],
    // },
  ];

  const { ref, inView } = useInView();
  const animation = useAnimation();
  useEffect(() => {
    if (inView) {
      animation.start({
        opacity: 1,
        y: -50,
        transition: {
          duration: 0.5,
        },
      });
    } else if (!inView) {
      animation.start({
        opacity: 0,
        y: 20,
      });
    }
  }, [inView]);

  const cardskill = (skill, i) => {
    return (
      <motion.div
        animate={animation}
        key={i}
        className="card-skill relative hover:bg-gradient-to-t hover:transition-shadow duration-1000 from-nav3 hover:shadow-2xl hover:shadow-sky-300 p-3 rounded-xl  w-56 text-center mx-2 pb-14 mt-48"
      >
        <img
          src={skill.img}
          alt="react"
          className="w-32 transition-transform duration- absolute -top-28 left-0 right-0 ml-auto mr-auto"
        />

        <h3 className="pt-8 text-lg font-sans font-semibold">{skill.title}</h3>
        <p className="text-sm font-body font-light">{skill.desc}</p>
        <div className="absolute w-full bottom-3">
          <div className="language flex flex-wrap justify-center mt-6">
            {skill.tech.map((ic, i) => {
              return (
                <div className="circle-icon" key={i}>
                  {ic}
                </div>
              );
            })}
          </div>
        </div>
      </motion.div>
    );
  };

  return (
    <div id="skill" className="container bg-background py-24">
      <h2 className="font-sans font-medium text-2xl text-center">Teknologi</h2>
      <div ref={ref} className="flex flex-wrap justify-center pt-10">
        {skills.map((skill, i) => cardskill(skill, i))}
      </div>
    </div>
  );
}

export default Skill;
