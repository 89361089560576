import React from 'react'
import { BsPlayCircleFill } from 'react-icons/bs'
import cover1 from '../Img/Thewall.jpg'
import cover2 from '../Img/cryin.jpg'
import cover3 from '../Img/toto.jpeg'
import cover4 from '../Img/240726-little-wing.jpeg'

function TopMusic() {    
    const top = [
        {id: '1', artist: 'Pink Floyd', nama: 'Comfortably Numb', img: cover1, like: true},
        {id: '2', artist: 'Aerosmith', nama: 'Cryin', img: cover2, like: false},
        {id: '3', artist: 'TOTO', nama: 'Rosanna', img: cover3, like: false},        
        {id: '4', artist: 'Jimy Hendrix', nama: 'Little Wing', img: cover4, like: false},
    ]
    return (
        <div className='container-music'>
            <h3 className="title-section">Top 20</h3>
            <table className='table-auto w-full border-spacing-y-4'>                
                <tbody>                    
                        {
                            top.map((t, i) => (                                
                                <tr key={i}>
                                    <td><p>{t.id}</p></td>
                                    <td className='py-2'><img src={t.img} alt="" className='circle-act'/></td>
                                    <td><h5>{t.nama}</h5></td>
                                    <td><p>{t.artist}</p></td>
                                    <td className='text-right'><button><BsPlayCircleFill className='text-indigo-500 text-xl'/></button></td>
                                </tr>
                            ))
                        }                    
                </tbody>
            </table>            
        </div>
    )
}

export default TopMusic