export const BarData =
[
	{
		region: "Palembang",
		laba: 51.87
	},
	{
		region: "Denpasar",
		laba: 21.63
	},
	{
		region: "Yogyakarta",
		laba: 75.43
	},
	{
		region: "Semarang",
		laba: 61.03
	},
	{
		region: "Jakarta",
		laba: 88.70
	}
]