import React, { useState } from "react";
import { motion } from "framer-motion";
import { useStateContext } from "../Contexts/ContextProvider";
import HeaderAdmin from "./Component/HeaderMusic";
import { useMediaQuery } from "react-responsive";
import NavbarMusic from "./Component/NavbarMusic";
import "./music.css";
import RightSidebarMusic from "./Component/RightSidebarMusic";
import ClipMusic from "./Component/ClipMusic";
import PopularMusic from "./Component/PopularMusic";
import TopMusic from "./Component/TopMusic";

function MusicAdmin() {
  // const [isOpen, setIsOpen] = useState(true);
  const isBigScreen = useMediaQuery({ query: "(min-width: 1024px)" });
  const { isOpen, setIsOpen } = useStateContext();
  const [content, setContent] = useState("");

  return (
    <div className="relative bg-admin" data-theme="light">
      <NavbarMusic />
      <motion.div
        animate={{
          marginLeft: isOpen && isBigScreen ? "200px" : "60px",
          transition: { duration: 0.5, type: "spring", damping: "10" },
        }}
        className="content grid grid-cols-12 min-h-screen overflow-hidden pb-10 sm:pb-0"
      >
        <div className="col-span-12 sm:col-span-7 md:col-span-8 lg:col-span-9">
          <HeaderAdmin />
          <div className="overflow-hidden w-full h-full">
            <ClipMusic />
            <PopularMusic />
            <TopMusic />
          </div>
        </div>
        <div className="right-sidebar col-span-12 sm:col-span-5 md:col-span-4 lg:col-span-3">
          <RightSidebarMusic />
        </div>
      </motion.div>
    </div>
  );
}

export default MusicAdmin;
