import React from "react";
import {
  BsArrowDownCircleFill,
  BsArrowRightCircle,
  BsFileEarmarkCode,
} from "react-icons/bs";
import { useCollapse } from "react-collapsed";
import { CopyBlock, dracula } from "react-code-blocks";

export default function ContentWiki(props) {
  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse({
    defaultExpanded: true,
  });
  return (
    <div className="relative py-2">
      <div
        className={`${
          isExpanded ? "absolute" : "hidden"
        } absolute top-1/2 translate-y-1/2 -rotate-90 -left-3`}
      >
        {props.data.title}
      </div>
      <div className="flex gap-4 text-2xl items-center">
        <label className="swap">
          <input
            type="checkbox"
            checked={isExpanded}
            value={isExpanded}
            {...getToggleProps()}
            readOnly
          />

          <BsArrowDownCircleFill className="swap-on fill-current" />

          <BsArrowRightCircle className="swap-off fill-current" />
        </label>
        {/* {isExpanded ? <BsArrowDownCircleFill /> : <BsArrowRightCircle />} */}

        <h2 {...getToggleProps()}>{props.data.title}</h2>
      </div>
      <section className="ml-3 pl-8 border-l-2" {...getCollapseProps()}>
        <div className="flex gap-4 text-lg items-center">
          <BsFileEarmarkCode />
          <h3 className="">{props.data.fileName}</h3>
        </div>
        <CopyBlock
          language={props.data.language}
          // text={code}
          text={props.data.code}
          codeBlock
          theme={dracula}
          showLineNumbers={true}
          // highlight="1,6,7"
          highlight={props.data.highlight.toString()}
          wrapLines
        />
        <p className="text-center font-light text-sm italic py-3">
          {props.data.caption}
        </p>
        <p className="paragraph">{props.data.description}</p>
      </section>
    </div>
  );
}
