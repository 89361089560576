import React, { useState } from "react";
import {
  BsFillGrid3X3GapFill,
  BsFillGridFill,
  BsGrid,
  BsGrid3X3Gap,
  BsSquare,
  BsSquareFill,
} from "react-icons/bs";
import Nyusss1 from "../imgs/galery/nyusss1.jpg";
import Nyusss2 from "../imgs/galery/nyusss2.jpg";
import Nyusss3 from "../imgs/galery/nyusss3.jpg";
import Nyusss4 from "../imgs/galery/nyusss4.jpg";
import Nyusss5 from "../imgs/galery/nyusss5.jpg";
import Nyusss6 from "../imgs/galery/nyusss6.jpg";
import Nyusss7 from "../imgs/galery/nyusss7.jpg";
import Nyusss8 from "../imgs/galery/nyusss8.jpg";
import Nyusss9 from "../imgs/galery/nyusss9.jpg";
import Nyusss10 from "../imgs/galery/nyusss10.jpg";
import Nyusss11 from "../imgs/galery/nyusss11.jpg";
import Nyusss12 from "../imgs/galery/nyusss12.jpg";
import Nyusss13 from "../imgs/galery/nyusss13.jpg";
import Nyusss14 from "../imgs/galery/nyusss14.jpg";
import Nyusss15 from "../imgs/galery/nyusss15.jpg";
import Nyusss16 from "../imgs/galery/nyusss16.jpg";
import Nyusss17 from "../imgs/galery/nyusss17.jpg";
import Nyusss18 from "../imgs/galery/nyusss18.jpg";
import { AnimatePresence, motion } from "framer-motion";

function FotonyaNyusss({ setSelectedImage }) {
  const [selectedGrid, setSelectedGrid] = useState(3);

  const images = [
    Nyusss1,
    Nyusss2,
    Nyusss3,
    Nyusss4,
    Nyusss5,
    Nyusss6,
    Nyusss7,
    Nyusss8,
    Nyusss9,
    Nyusss10,
    Nyusss11,
    Nyusss12,
    Nyusss13,
    Nyusss14,
    Nyusss15,
    Nyusss16,
    Nyusss17,
    Nyusss18,
  ];
  const gridIcons = [
    {
      id: 3,
      selected: <BsFillGrid3X3GapFill />,
      notSelected: <BsGrid3X3Gap />,
    },
    { id: 2, selected: <BsFillGridFill />, notSelected: <BsGrid /> },
    { id: 1, selected: <BsSquareFill />, notSelected: <BsSquare /> },
  ];
  return (
    <>
      <div className="flex justify-around py-2 items-center">
        {gridIcons.map((gridIcon) => {
          const isSelected = selectedGrid === gridIcon.id;
          return (
            <div
              key={gridIcon.id}
              className={`flex justify-center flex-1 ${
                isSelected && "border-b-2 border-pink-500"
              } p-2 text-2xl`}
              onClick={() => setSelectedGrid(() => gridIcon.id)}
            >
              {isSelected ? gridIcon.selected : gridIcon.notSelected}
            </div>
          );
        })}
      </div>
      <div className={`grid grid-cols-${selectedGrid} gap-1`}>
        {images.map((image, i) => {
          return (
            <motion.img
              key={i}
              src={image}
              alt="Yustika Indah Maharani"
              className={`${
                selectedGrid === 1 ? "" : "aspect-square"
              }  object-cover`}
              onClick={() => setSelectedImage(image)}
              // onClick={() => console.log("clicked")}
            />
          );
        })}
      </div>
    </>
  );
}

export default FotonyaNyusss;
