import React, { useEffect } from "react";
import { useStateContext } from "../Contexts/ContextProvider";
import WikiData from "../DummyData/Wiki.json";
import Breadcrumb from "./Component/Breadcrumb";
import ContentWiki from "./Component/ContentWiki";
import FloatingActionButton from "./Component/FloatingActionButton";
import SidebarWiki from "./Component/SidebarWiki";
import "./assets/css/wiki.css";

function Wiki() {
  const { isDark, setIsDark } = useStateContext(false);
  // const [data, setData] = useState({});

  useEffect(() => {
    document.title = "F0bima Wiki - Lorem";
  });

  return (
    <div
      data-theme={isDark ? "night" : "cupcake"}
      className="transition min-h-screen"
    >
      <SidebarWiki />
      <FloatingActionButton />
      <div className="lg:pl-96 px-5 md:px-28">
        <div className="p-4">
          <div className="wiki-tittle my-4 py-2 border-b-2">
            <h1 className="font-extrabold text-3xl">{WikiData.title}</h1>
            <p className="font-light text-sm">
              Terakhir diupdate :{" "}
              <span className="font-medium">{WikiData.edited}</span>
            </p>
            <Breadcrumb data={WikiData.navigation} />
          </div>
          <p className="paragraph">{WikiData.description}</p>
          {WikiData.codes.map((data, idx) => {
            return <ContentWiki data={data} key={idx} />;
          })}
        </div>
      </div>
    </div>
  );
}

export default Wiki;
