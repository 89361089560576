import { motion, useInView } from "framer-motion";
import Lottie from "lottie-react";
import React, { useRef } from "react";
import { AiFillHeart } from "react-icons/ai";
import Mima from "../imgs/mima.jpg";

function PostCard({ animation, img, caption, rootView }) {
  const ref = useRef(null);
  const isInview = useInView(ref, { root: rootView, once: false });
  const variantsUp = {
    open: { display: "block", opacity: 1, y: 0 },
    closed: {
      opacity: 0,
      y: 20,
      transitionEnd: {
        display: "none",
      },
    },
    hidden: { y: 20, opacity: 0 },
  };
  const variantsLeft = {
    open: { display: "block", opacity: 1, x: 0 },
    close: {
      opacity: 0,
      x: 50,
      transitionEnd: {
        display: "none",
      },
    },
    hidden: { x: 50, opacity: 0 },
  };
  const transition = { delay: 0.1, type: "spring", damping: 8 };

  return (
    <div className="rounded-xl shadow-xl w-full bg-white overflow-hidden my-4">
      {img && <img className="w-full" src={img} alt="Nyuss ku" />}
      {animation && (
        <Lottie className="w-full" animationData={animation} loop={true} />
      )}
      <div className="p-2">
        <div ref={ref} className="flex justify-between gap-1 items-center py-1">
          <motion.div
            initial="hidden"
            animate={isInview ? "open" : "close"}
            transition={transition}
            variants={variantsUp}
            className="flex items-center gap-1"
          >
            <AiFillHeart className="text-red-600 w-5 h-5" />
            <p className="text-xs font-light">Mima suka nyuss</p>
          </motion.div>
          <motion.div
            initial="hidden"
            animate={isInview ? "open" : "close"}
            transition={transition}
            variants={variantsLeft}
            className="flex items-center gap-1 border-l-2 pl-2"
          >
            <p className="text-xs font-light">Mima</p>
            <img
              src={Mima}
              alt=""
              className="w-6 h-6 object-cover rounded-full"
            />
          </motion.div>
        </div>
        <p className="py-1">{caption}</p>

        <p className="text-xs font-light py-1">
          Semuanya berkomentar nyusss cantik banget
        </p>
      </div>
    </div>
  );
}

export default PostCard;
