import React, { useRef } from "react";
import TemplateNyusss from "./Components/TemplateNyusss";
import Mima from "./imgs/mima.jpg";
import Nyusss from "./imgs/nyuss3.jpg";
import { Link } from "react-router-dom";
import { motion, useInView } from "framer-motion";

function NotifCard({ message, date, rootView }) {
  const ref = useRef(null);
  const isInview = useInView(ref, { root: rootView, once: false });
  const variants = {
    open: { display: "block", opacity: 1, scale: 1 },
    close: {
      opacity: 0,
      scale: 0,
    },
    hidden: { opacity: 0, scale: 0 },
  };
  return (
    <motion.div
      ref={ref}
      initial="hidden"
      animate={isInview ? "open" : "close"}
      // animate={"open"}
      transition={{
        delay: 0.1,
        type: "spring",
        damping: 8,
      }}
      variants={variants}
      className="flex justify-between items-center shadow-xl rounded-2xl p-2 gap-1"
    >
      <div className="flex-none w-12 h-12">
        <img
          src={Mima}
          alt=""
          className="w-12 h-12 object-cover rounded-full"
        />
      </div>
      <div className="flex flex-col flex-auto gap-1">
        <p className="text-xs font-medium">{message}</p>
        <p className="text-xs text-neutral-600">{date}</p>
      </div>
      <div className="flex-none w-12 h-12">
        <img
          src={Nyusss}
          alt=""
          className="w-12 h-12 object-cover rounded-full"
        />
      </div>
    </motion.div>
  );
}
function NotifNyusss() {
  const containerRef = useRef(null);
  const notifications = [
    {
      message: "Mima mulai ngelirik nyusss, modus kang foto",
      date: "25 November 2017",
    },
    {
      message: "Nyusss modus project game",
      date: "24 Febuari 2022",
    },
    {
      message:
        "Nyusss nyamperin mima. Jetz nya lengket banget kayak kita. Masih lengket kita sih",
      date: "27 Febuari 2022",
    },
    {
      message: "Nyusss kangen, Ngechat lagi nanyain game",
      date: "11 Januari 2023",
    },
    {
      message: "Mima ama nyusss modus modusan, eh kok jadian beneran",
      date: "Ramadhan 1444 H",
    },
    {
      message: "Nyusss meluk mima pas First Date",
      date: "20 April 2023",
    },
    {
      message: "Nyusss nyender di pundak mima pas Nonton",
      date: "25 April 2023",
    },
    {
      message: "Mima ngajakin nyusss kerumah kenalan ama bapak",
      date: "1 Juni 2023",
    },
    {
      message: "Ke kaliurang sama nonton standup. Mima tidur di pundak nyusss",
      date: "3 Juni 2023",
    },
    {
      message: "Mima pengin jadi suaminya nyusss",
      date: "Selamanya",
    },
    {
      message: "Mima pengin jadi teman curhat nyusss",
      date: "Selamanya",
    },
    {
      message: "Mima pengin jadi rumahnya nyusss",
      date: "Selamanya",
    },
    {
      message: "Mima pengin sama nyusss",
      date: "Selamanya",
    },
  ];
  return (
    <TemplateNyusss isShowNavbar={false}>
      <div className="absolute z-40 top-0 p-2 bg-white shadow-xl w-full">
        <h1 className="text-3xl font-extrabold p-3">Tentang Kita</h1>
      </div>
      <div
        ref={containerRef}
        className="h-full overflow-y-auto pt-20 pb-16 flex flex-col p-3 scrollbar-hide"
      >
        <ul>
          {notifications.map((notification, index) => {
            return (
              <li key={index} className="p-2">
                <NotifCard
                  message={notification.message}
                  date={notification.date}
                  rootView={containerRef}
                />
              </li>
            );
          })}
        </ul>
      </div>

      <div className="absolute bottom-0 w-full p-2 ">
        <Link to={"/nyusss/"} replace>
          <button className="bg-gradient-to-br shadow-xl rounded-xl from-pink-500 to-red-500 w-full h-full py-2 text-neutral-100 font-bold">
            Yuk bikin lagi honey
          </button>
        </Link>
      </div>
    </TemplateNyusss>
  );
}

export default NotifNyusss;
