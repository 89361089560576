import React from "react";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import Navbar from "./Components/Navbar";
import Project from "./Components/Project";
import Skill from "./Components/Skill";
import Timeline from "./Components/Timeline";

function Dashboard() {
  return (
    <div data-theme="light" className="bg-background">
      <Navbar />
      <Header />
      <Timeline />
      <Skill />
      <Project />
      <Footer />
    </div>
  );
}

export default Dashboard;
