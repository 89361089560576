import React, { useRef } from "react";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { BsHeartFill } from "react-icons/bs";
import Slider from "react-slick";
import cover4 from "../Img/cryin.jpg";
import cover1 from "../Img/dark-side-of-the-moon.jpg";
import cover2 from "../Img/toto.jpeg";
import cover3 from "../Img/wish-you-were-here.jpg";

function PopularMusic() {
  const sliderRef = useRef(null);
  const next = () => {
    sliderRef.current.slickNext();
  };
  const previous = () => {
    sliderRef.current.slickPrev();
  };
  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 960,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      // {
      //   breakpoint: 800,
      //   settings: {
      //     slidesToShow: 2,
      //     slidesToScroll: 2,
      //   }
      // },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };
  const popularLists = [
    { id: "1", artist: "Pink Floyd", nama: "Time", img: cover1, like: true },
    { id: "2", artist: "TOTO", nama: "Africa", img: cover2, like: false },
    {
      id: "3",
      artist: "Pink Floyd",
      nama: "Wish You Were Here",
      img: cover3,
      like: false,
    },
    { id: "4", artist: "Aerosmith", nama: "Cryin", img: cover4, like: false },
    { id: "5", artist: "Pink Floyd", nama: "Time", img: cover1, like: true },
    { id: "6", artist: "TOTO", nama: "Africa", img: cover2, like: false },
    {
      id: "7",
      artist: "Pink Floyd",
      nama: "Wish You Were Here",
      img: cover3,
      like: false,
    },
    { id: "8", artist: "Aerosmith", nama: "Cryin", img: cover4, like: false },
  ];
  return (
    <div className="container-music">
      <div className="title-section">
        <h3 className="">Popular Music</h3>
        <div className="">
          <button className="button-carousel" onClick={previous}>
            <BiChevronLeft />
          </button>
          <button className="button-carousel" onClick={next}>
            <BiChevronRight />
          </button>
        </div>
      </div>
      {/* <div className="flex columns-3 justify-between"> */}
      <div className="w-full ">
        <Slider ref={sliderRef} {...settings}>
          {popularLists.map((pop, i) => (
            <div key={i} className="">
              <div className="flex flex-col justify-center items-center">
                <div className="">
                  <button>
                    <img
                      src={pop.img}
                      alt=""
                      className="object-cover h-32 sm:h-40 md:h-44 lg:h-52 xl:h-60 popular-card aspect-[4/5]"
                    />
                  </button>
                  <div className="py-3 flex justify-between items-center w-full gap-2">
                    <div className="">
                      <h4 className="text-xs xl:text-sm font-semibold">
                        {pop.nama}
                      </h4>
                      <p className="text-sm">{pop.artist}</p>
                    </div>
                    <button>
                      <BsHeartFill
                        className={`text-lg ${
                          pop.like ? "text-red-700" : "text-gray-400"
                        }`}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default PopularMusic;
